import * as React from "react"
import { usePerfectCursor } from "../../hooks/usePerfectCursors"
import { useDispatch, useSelector } from "react-redux";
import { Cursor } from "./Cursor"
import { CursorVideo } from "./CursorVideo";
import styled from "styled-components";

const DotsFlashing =  styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  margin-left: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: white;
  color: white;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props => props.mainColor};
    color: ${props => props.mainColor};
    animation: dotFlashing 0.5s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color:${props => props.mainColor};
    color: ${props => props.mainColor};
    animation: dotFlashing 0.5s infinite alternate;
    animation-delay: 0.5s;
  }

  @keyframes dotFlashing {
    0% {
        background-color: ${props => props.mainColor};
    }
    50%,
    100% {
        background-color: white;
    }
  }
`;

export function CursorPerfect({ point, message, color, isTyping, isGuide, numberOfGuideUsers}) {
  const rCursor = React.useRef(null)

  const animateCursor = React.useCallback((point) => {
    const elm = rCursor.current
    if (!elm) return
    elm.style.setProperty(
      "transform",
      `translate(${point[0]}px, ${point[1]}px)`
    )
  }, [])

  const onPointMove = usePerfectCursor(animateCursor)

  React.useLayoutEffect(() => onPointMove(point), [onPointMove, point])
  const reverseMessage = message?.slice(0).reverse();

  return (
    <div
      ref={rCursor}
      className="cursor-other"
    >
      <Cursor color={color}></Cursor>
        {isGuide && 
          <CursorVideo color={color} numberOfGuideUsers={numberOfGuideUsers}></CursorVideo>
        }
        {(reverseMessage || isTyping) &&
            <div className="messages__wrapper">
            {isTyping &&
              <DotsFlashing mainColor={color?.primary} />
            }
            {reverseMessage.map((message, index) =>
                <div className={'message message_visibility__' + message.status } key={index} style={{ backgroundColor: color?.primary, color: color?.text }}>{message?.text}</div>
            )   }
            </div>
        }
    </div>
  )
}