import React,{ useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { CursorLocal } from './CursorLocal';

export const MeCursor = ({numberOfGuideUsers}) => {
    
        return (
            <>
                <CursorLocal numberOfGuideUsers={numberOfGuideUsers}></CursorLocal>
            </>
        );
};