import React from 'react'
import {Helmet} from "react-helmet";

export class HelmetHeader extends React.Component {

  render() {
    const { title, seoSlug, description, imageUrl } = this.props;

    return (
      <Helmet>
        <meta property="og:title" content={title} />
        <meta property="og:url" content={process.env.REACT_APP_EXHIBIT_PUBLIC_URL + seoSlug} />
        <meta property="og:description" content={description} />
        {
          imageUrl ?
          <meta property="og:image" content={imageUrl} />
          :
          <meta property="og:image" content={imageUrl} />
        }
        <meta name="description" content={description} />
        <link rel="canonical" href={process.env.REACT_APP_EXHIBIT_PUBLIC_URL + seoSlug} />
        <title>{title}</title>
      </Helmet> 
    )
  }

}