import _ from "lodash";
import { colors } from "./colors";

export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const getViewportSize = () => {
    if ((window).getViewportSize) {
        return (window).getViewportSize;
    }

    window.onresize = () => {
        (window).getViewportSize = {
            width:
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth,
            height:
                window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight,
        };
    };

    (window).onresize();

    return (window).getViewportSize;
};

export const getScale = (x, y) => {
    const { width, height } = getViewportSize();
    return {
        scaleX: x / width,
        scaleY: y / height,
    };
};

export const getMousePosition = (scaleX, scaleY) => {
    const { width, height } = getViewportSize();
    return {
        mouseX: scaleX * width,
        mouseY: scaleY * height,
    };
};

export const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff;
        color += ('00' + value.toString(16)).slice(-2);
    }
    return color;
};

export const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
};

export const getRandomColorExludingArray = (myColor, existingColors) => {
    if(!existingColors){
        return myColor;
    }

    if(_.findIndex(existingColors, myColor) === -1) {
        return myColor
    }

    if(existingColors.length >= colors.length){
        return myColor;
    } else {
        let filteredColors = _.differenceBy(colors, existingColors, 'primary');
        if(filteredColors.length > 1) {
            return filteredColors[Math.floor(Math.random() * filteredColors.length)];
        } else {
            return colors[Math.floor(Math.random() * colors.length)];
        }
    }
};