import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { OtherCursor } from './OtherCursor';
import './styles/CursorChat.scss';
import './styles/Cursor.scss';
import { MeCursor } from './MeCursor';
import { useLocation } from "react-router-dom";
import baadActions from '../../redux/actions';

import { configureAbly, usePresence } from "@ably-labs/react-hooks";
import { v4 as uuidv4 } from 'uuid';
import { throttle } from 'lodash';
import { getRandomColorExludingArray, getScale } from './helper';

const myClientId = uuidv4();
configureAbly({ key: "ui5Dvw.qIofNg:lWbJY1hCsu3O5G1I_jBydpr9jvfUwJoO5QU7PSO8Js8", clientId: myClientId });

export const CursorChat = ({isGuide, guideUrl }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const meCursorScalePos = useSelector((state) => state.cursor);
    const meRoom = useSelector((state) => state.room);
    const meMessage = useSelector((state) => state.message);
    const meColor = useSelector((state) => state.color);
    const meIsTyping = useSelector((state) => state.isTyping);
    const meUserStatus = useSelector((state) => state.userStatus);

    const [colorIsChecked, setColorIsChecked] = useState(false);
    const [readyToCheckColor, setReadyToCheckColor] = useState(false);

    const [presenceData, updateStatus] = usePresence('queendom', {cursor:  { scaledPos: {scaleX: 0.5, scaleY: 0.5}}, room: false, message: [], color: meColor, isTyping: false, userStatus: meUserStatus });
    //const meOnline = presenceData.filter((user) => user.clientId === myClientId ).map((user) => user.data).shift();
    const allEverywhere = presenceData.map((user) => user.data);
    const othersEverywhere = presenceData.filter((user) => user.clientId !== myClientId).map((user) => user.data);
    const othersHere = presenceData.filter((user) => user.clientId !== myClientId && user.data.room === meRoom).map((user) => user.data);
    //const guidePresent = othersHere.some((user) => user.userStatus.isGuide);
    const guideComplex = allEverywhere.find((user) => user.userStatus?.isGuide) ?? false;
    const numberOfPeopleInGuide = presenceData.filter((user) => user.data?.userStatus?.joinedGuide).map((user) => user.data)?.length;
    const {color, cursor, isTyping, message, ...guide} = guideComplex;

    const handlePointerMove = (e) => {
        let scaledPos = getScale(e.clientX, e.clientY);
        dispatch(baadActions.setCursor(scaledPos));
        throttle_this( meMessage, location.pathname, { scaledPos }, meColor, meIsTyping, meUserStatus);
    };

    /// ATTENTION ///
    /// the throttle value is set to 1000ms for development
    // before you lunch to production please set it to 500ms
    // don't use 500m ins development, it costs a lot of messages, thx
    const throttle_this = useCallback(
        throttle((message, room, cursor, color, isTyping, meUserStatus) => {
            //console.log('update status 1');
            updateStatus({ message: message, room: room, cursor: cursor, color: color, isTyping: isTyping, userStatus: meUserStatus});
        }, 750),

        []
    );

    useEffect(() => {
        if(readyToCheckColor && !colorIsChecked && meColor){
            let otherColors = othersHere.map((user_data) => user_data.color);
            dispatch(baadActions.setColor(getRandomColorExludingArray(meColor, otherColors)));
            setColorIsChecked(true);
        }
    }, [presenceData, othersHere, dispatch, meColor, readyToCheckColor, colorIsChecked]);

    useEffect(() => {
        document.addEventListener('mousemove', handlePointerMove);
        //better handling of updates here, trottle?
        //console.log('update status 2');
        updateStatus({ message: meMessage, room: meRoom, cursor: meCursorScalePos, color: meColor, isTyping: meIsTyping, userStatus: meUserStatus});

        return () =>{
            document.removeEventListener('mousemove', handlePointerMove);
        }
    }, [location, meMessage, meColor, meRoom, meIsTyping, meUserStatus]);

    useEffect(() => {
        if(guide?.userStatus && guide?.room ){

            dispatch(baadActions.setGuideStatus({
                guidePresent: guide?.userStatus?.isGuide,
                guideUrl: guide?.userStatus?.myVideoUrl,
                guideRoom: guide?.room
            }));
        } else {
            dispatch(baadActions.setGuideStatus({
                guidePresent: false,
                guideUrl: false,
                guideRoom: false
            }));
        }
    }, [dispatch, guide]);

    useEffect(() => {
        dispatch(baadActions.setRoom(location.pathname));
    }, [dispatch, location]);

    useEffect(() => {
        dispatch(baadActions.setPresenceData(presenceData.filter((user) => user.clientId !== myClientId ).map(data => data.data)));
        dispatch(baadActions.setMeData({ message: meMessage, room: meRoom, cursor: meCursorScalePos, color: meColor, isTyping: meIsTyping, userStatus: meUserStatus}));
    }, [dispatch, presenceData, meMessage, meColor, meRoom, meCursorScalePos, meIsTyping, meUserStatus])

    useEffect(() => {
        dispatch(baadActions.startIntroMessageLocalSaga());
        let timer1000 = setTimeout(() => setReadyToCheckColor(true), 1000);
        if(isGuide && guideUrl) {
            dispatch(baadActions.setUserStatus({isGuide: true, joinedGuide: false, myVideoUrl: guideUrl}));
        }

        return () => {
            clearTimeout(timer1000);
        };
    }, [])

    return (
        <div className="cursor-chat__wrapper">
            <MeCursor numberOfGuideUsers={numberOfPeopleInGuide}></MeCursor>
            {othersHere.map((user, index) => <OtherCursor key={'other_' + index} position={user?.cursor?.scaledPos} room={user?.room} message={user?.message} color={user?.color} isTyping={user?.isTyping} isGuide={user?.userStatus?.isGuide} numberOfGuideUsers={numberOfPeopleInGuide}></OtherCursor>) }
        </div>
    );
};