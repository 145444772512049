import constants from './appConstant';
const BASE_URL = constants.BASE_URL;
const BASE_URL2 = constants.BASE_URL2;
const CATEGORY_ID = constants.CATEGORY_ID;
const EXHIBITIONS_CATEGORY_IDS = constants.EXHIBITIONS_CATEGORY_IDS;
const INTRO_URL = constants.INTRO_URL;
// const TOKEN = constants.API_TOKEN;
class HttpProcessor {
    /*  You can include or exclude the categories. e.g
    &exclude=1,18,19,20
    &include=22,23 */
    // get categories with exclude ids
    getCategories(){
        return fetch(`${BASE_URL}categories?per_page=100&orderby=id&exclude=1,18`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
        })
        .then(response => response.json());
    }
    getCategoriesData(id, count){
        return fetch(`${BASE_URL2}posts?category=${id}&per_page=${count}`,
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
        })
        .then(response => response.json());
    }
    getPost(postId){
        return fetch(`${BASE_URL}posts/${postId}`,
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
        })
        .then(response => response.json());
    }
    getPostBySlug(slug){
        return fetch(`${BASE_URL}posts?slug=${slug}`,
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
        })
        .then(response => response.json());
    }
    getPageBySlug(slug){
        return fetch(`${BASE_URL}pages/?slug=${INTRO_URL}`,
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache'
        })
        .then(response => response.json());
    }
    getHumanData(){
        let categoryMap = Object.values(EXHIBITIONS_CATEGORY_IDS);
        let categoryUrls = Object.keys(EXHIBITIONS_CATEGORY_IDS);
        let urlToMapIndex = categoryUrls.indexOf(window.location.pathname);
        let categoryIdToUse = CATEGORY_ID;
        if( urlToMapIndex > -1 ){
            let panelCategoryId = categoryMap[urlToMapIndex];
            categoryIdToUse = panelCategoryId;
        }
        return fetch(`${BASE_URL}posts/?categories=${categoryIdToUse}&_fields[]=acf&_fields[]=slug&per_page=99`,
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache'
        })
        .then(response => response.json());
    }

    getExhibitionsData() {
        return fetch(`${BASE_URL}pages/?slug=exhibitions`,
            {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache'
            })
            .then(response => response.json());
    }

    getQueendomData(){
        return fetch(`${BASE_URL}pages/?slug=queendom`,
            {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache'
            })
            .then(response => response.json());
    }

    getRoomDataLiveblocks(){
        return fetch("https://api.liveblocks.io/v2/rooms", {
          method: "GET",
          mode: 'no-cors',
          headers: {
            Authorization: "Bearer sk_dev_CsrlCRYS7p454wTwLmvh3Fuh",
          },
        }).then(response => response.json());
    }

}

export default HttpProcessor;
