import React, { useState, useRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Button from "../Button/Button";
import Input from "../Input/Input";
import './humanPostForm.scss';
import emailjs from "@emailjs/browser";
import ReactHtmlParser from "react-html-parser";

export default function HumanPostForm({ isContactOpen, openContactForm, humanPostData, activeStep, handlePostSubmit, contributionData }) {
    const form = useRef();
    const history = useHistory()
    const { register, handleSubmit, formState: { errors } } = useForm();
    let [walletValue, setWalletValue] = useState("");
    let [messageValue, setMessageValue] = useState(`Dear Rüdiger Schöttle gallery, \nI would like to purchase the work ‘${contributionData?.title}’ . \nKind regards`);

    const onContactFormSubmit = () => {
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_USER_ID)
            .then((result) => {
                handlePostSubmit(2)
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className={isContactOpen ? 'human-post-form human-post-form--active' : 'human-post-form'}>
            {activeStep !== 2 &&
            <div className="art-details-wrapper">
                <h3 onClick={() => openContactForm(false)} className="contact-body__subtitle">≤ BACK TO ARTWORK</h3>
                <div className="art-details">
                    <div className="art-details__img-wrapper">
                        <img src={contributionData?.labelImage[0]?.url} alt="" className="art-details__img"/>
                    </div>
                    <div>
                        <p className="art-details__text">{contributionData?.title}</p>
                        <p className="art-details__text">{contributionData?.artistName && contributionData?.artistName}</p>
                        <p className="art-details__text">
                            PRICE: {contributionData?.price ? contributionData?.price : 'N/A'} €
                        </p>
                    </div>
                </div>
            </div>
            }
            <form ref={form} onSubmit={handleSubmit(onContactFormSubmit)}>
                <div className={activeStep === 1 || activeStep === 2 ? 'form-hidden' : null}>
                    <div>
                        <h3 className="contact-body__subtitle">ENTER E-MaiL ADDRESS ≥</h3>
                        <Input
                            required={true}
                            register={register}
                            name="email"
                            type="email"
                            placeholder="E-mail"
                            errors={errors.email} />

                        <Input
                            required={true}
                            register={register}
                            name="emailRepeat"
                            type="email"
                            placeholder="Confirm E-mail"
                            errors={errors.emailRepeat} />

                        <h3 className="contact-body__subtitle mt-5">CRYPTO WALLET ≥</h3>
                        <p className="contact-body__text">
                            Do you own an Ethereum wallet already?
                        </p>
                        <div className="input-radio-wrapper">
                            <div className="input">
                                <div className="input-radio">
                                    <input
                                        {...register("walletOption", { required: true })}
                                        value="yes"
                                        name="crypto_wallet"
                                        type="radio"
                                        checked={walletValue === "yes"}
                                        onChange={() => {setWalletValue("yes")}}
                                    />
                                    <label onClick={() => {setWalletValue("yes")}} className="input-radio__label">YES, I HAVE ONE</label>
                                </div>
                            </div>
                            <div className="input">
                                <div className="input-radio">
                                    <input
                                        {...register("walletOption", { required: true })}
                                        value="no"
                                        name="crypto_wallet"
                                        type="radio"
                                        checked={walletValue === "no"}
                                        onChange={() => {setWalletValue("no")}}
                                    />
                                    <label onClick={() => {setWalletValue("no")}} className="input-radio__label">NO, I DON’t </label>
                                </div>
                            </div>
                        </div>
                        {errors.walletOption && (
                            <span role="alert" className="form-alert">
                                This field is required
                            </span>
                        )}
                        {walletValue === "yes"
                            ?
                            <>
                                <p className="contact-body__text">
                                    You can enter your wallet address here (optional)
                                </p>
                                <Input
                                    required={true}
                                    register={register}
                                    name="wallet"
                                    placeholder="Wallet address"
                                    errors={errors.wallet} />
                            </>
                            :
                            null
                        }
                    </div>
                    {walletValue !== '' &&
                    <div className="contact-footer">
                        <a onClick={() => openContactForm(false)} className="btn--link">
                            &lt;BACK
                        </a>
                        <span onClick={handleSubmit(() => handlePostSubmit(1))}>
                            <Button type="button" form="step-1" btnText="Next" dark />
                        </span>
                    </div>
                    }
                </div>
                {activeStep === 1 &&
                <>
                    <h3 className="contact-body__subtitle">
                        THIS WORK OF ART IS THE COURTESY OF ILLIT AZOULAY.
                    </h3>
                    <p className="contact-body__subtitle mb-5">
                        THE GALLERY STAFF WILL SEE TO THE TRANSFER OF YOUR NFT. Please fill in this form FOR THE invoice. ≥
                    </p>
                    <Input
                        required={true}
                        register={register}
                        name="name_surname"
                        type="text"
                        placeholder="Name Surname..."
                        errors={errors.name_surname} />

                    <Input
                        required={true}
                        register={register}
                        name="address"
                        type="text"
                        placeholder="Address..."
                        errors={errors.address} />

                    <Input
                        required={true}
                        register={register}
                        name="country"
                        type="text"
                        placeholder="State and Country..."
                        errors={errors.country} />

                    <Input
                        register={register}
                        name="vat_number"
                        type="text"
                        placeholder="Vat Number (Optional)" />

                    <textarea
                        placeholder={`Dear Rüdiger Schöttle gallery, \nI would like to purchase the work ‘${humanPostData.title.rendered}’ . \nKind regards`}
                        value={messageValue}
                        onChange={(e) => {setMessageValue(e.target.value)}}
                        name="message"
                        id=""
                        className="input--outlined textarea--outlined mt-5"
                    />
                    <div className="contact-footer">
                        <a onClick={() => handlePostSubmit(0)} className="btn--link">
                            &lt;BACK
                        </a>
                        <Button type="submit" btnText="Buy" dark />
                    </div>
                </>
                }
            </form>
            {activeStep === 2 &&
            <div className="step3 text-center">
                <img src={require('../../assets/success-tick.svg')} alt="" />
                {walletValue === 'no'
                    ?
                    <>
                        <p className="contact-body__subtitle">
                            Thank you! <br/> Your request has been sent.
                        </p>
                        <p className="contact-body__subtitle mb-5">
                            We will get back to you as soon as possible!
                        </p>
                    </>
                    :
                    <p className="contact-body__subtitle">
                        CONGRATULATIONS! <br/> Your NEWLY PURCHASED NFT WILL BE <br/> TRANSFERRED TO YOUR WALLET SOON.
                    </p>
                }

                <a onClick={(e) => history.goBack(e)} className="btn--link">
                    ≤ GO BACK TO THE EXHBITION
                </a>
            </div>
            }
        </div>
    );
}
