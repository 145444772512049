import React, { useState, useEffect } from 'react';
import './Navigation.scss';
import ReactHtmlParser from 'react-html-parser';

function Navigation(
    {
        exhibitionsData,
        handleShow,
        show,
        handleExhibitionRedirect,
        exhibitionName,
        superScript,
        exhibtionPos,
        mainData,
    }) {

    let [openPanel, setOpenPanel] = useState(exhibtionPos);
    let [openSource, setOpenSource] = useState(null);
    let [hoveredPlanet, setHoveredPlanet] = useState(0);
    let [openPlanet, setOpenPlanet] = useState(null);
    let [exhibitionData, setExhibitionData] = useState(null);
    let [transition, setTransition] = useState('slideInRight');
    let radius = 0;

    const connectPlanets = {
        "1": [1, 2],
        "2": [2, 3],
        /*"3": [3, 4],
        "4": [4, 5],
        "5": [5, 6],
        "6": [6, 7]*/
    }

    useEffect(() => {
        /*if (exhibitionsData[0]) {
            setExhibitionData(exhibitionsData[0].acf.exhibition_panels)
        }*/
        if (mainData?.constellationSettings?.length > 0) {
            setExhibitionData(mainData?.constellationSettings)
        }
    });

    useEffect(() => {
        if (!show) return

        const planets = document.getElementsByClassName('planet');
        const lines = document.getElementsByClassName('line--planet');

        placeStars(false, exhibtionPos);
        setHoveredPlanet(exhibtionPos);

        Object.entries(connectPlanets).map(([key]) => {
            connectConstellation(
                planets[connectPlanets[key][0]],
                [planets[connectPlanets[key][1]]],
                [lines[key]]
            )
        });
    }, [show]);


    if (!exhibitionData) {
        return null;
    }

    const handlePanelOpen = (index) => {
        const navigation = document.querySelector('.navigation');

        setOpenPanel(index);
        setOpenSource(null);

        navigation.scrollTo(0, 0);
    }

    const handleSourceOpen = (exhibitionSourceIndex) => {
        setOpenSource(exhibitionSourceIndex);
    }

    const closeSource = () => {
        setOpenSource(null)
    }

    const handlePreviousPanel = () => {
        if (openPanel <= 0) return null;

        setOpenPanel(openPanel-1);
        setTransition('slideInLeft');
    }

    const handleNextPanel = () => {
        const exhibitionItems = exhibitionData.filter(exhibition => {
            return exhibition.panel[0].exhibitionIsActive === true
        })

        if (openPanel > exhibitionItems.length-2) return null;

        setOpenPanel(openPanel+1);
        setTransition('slideInRight');
    }

    function connectConstellation(from, to, line){
        if (!from || !to || !line) {
            return null;
        }

        let left;
        let top;
        const fT = from.offsetTop + from.offsetHeight / 2;
        const fL = from.offsetLeft + from.offsetWidth / 2;

        for (let i = 0; i < to.length; i++) {
            const tT = to[i].offsetTop + to[i].offsetHeight / 2;
            const tL = to[i].offsetLeft + to[i].offsetWidth / 2;

            const CA = Math.abs(tT - fT);
            const CO = Math.abs(tL - fL);
            const H = Math.sqrt(CA * CA + CO * CO);
            let ANG = 180 / Math.PI * Math.acos(CA / H);

            if (tT > fT) {
                top = (tT - fT) / 2 + fT;
            } else {
                top = (fT - tT) / 2 + tT;
            }
            if (tL > fL) {
                left = (tL - fL) / 2 + fL;
            } else {
                left = (fL - tL) / 2 + tL;
            }

            if ((fT < tT && fL < tL) || (tT < fT && tL < fL) || (fT > tT && fL > tL) || (tT > fT && tL > fL)) {
                ANG *= -1;
            }
            top -= H / 2;

            line[i].style.transform = `rotate(${ANG}deg)`;
            line[i].style.top = top + 'px';
            line[i].style.left = left + 'px';
            line[i].style.height = H + 'px';
        }
    }

    function placeStars(e, index) {
        if(e){
            e.preventDefault();
        }
        if (openPlanet === index) return null;

        let radius = 0;

        setTimeout(() => {
            const fields = document.getElementsByClassName('star');
            const width = 30;
            const height = 30;
            let angle = 0;
            let step = (2*Math.PI) / fields.length;

            const counting = setInterval(() => {
                if(radius < 80) {
                    radius++

                    for (let i = 0; i < fields.length; i++) {
                        const x = Math.round(width/2 + radius * Math.cos(angle) - fields[i].offsetWidth/2);
                        const y = Math.round(height/2 + radius * Math.sin(angle) - fields[i].offsetHeight/2);

                        fields[i].style.left = x + 'px';
                        fields[i].style.top = y + 'px';
                        angle += step;
                    }

                    connectConstellation(
                        document.getElementById('planet'),
                        document.getElementsByClassName('star'),
                        document.getElementsByClassName('line'),
                    )
                } else {
                    clearInterval(counting)
                }
            }, 3);
        }, 5);

        setOpenPlanet(index);
        setOpenSource(null);
    }

    const exhibitionRedirect = (exhibitionLink) => {
        handleExhibitionRedirect(exhibitionLink);
    }

    return (
        <>
            <div
                className={`${show ? 'navigation-page__bg navigation-page__bg--active' : 'navigation-page__bg'}`}
                onClick={handleShow}
            />
            <div className={show ? 'navigation navigation--mobile navigation--active' : 'navigation navigation--mobile'}>
                <div className="navigation__wrapper">
                    <div>
                        {exhibitionData.map(({ panel, panelImage, sources }, i) => {
                            return (
                                <React.Fragment key={'exhib' + i}>
                                    {panel[0].exhibitionIsActive === true ?
                                        <>
                                            <div className={'item ' + (openPanel === i ? 'item--active slideInLeft ' : 'slideInRight') + transition}>
                                                <>
                                                    <div className="navigation__item" onClick={() => handlePanelOpen(i)}>
                                                        <div className="navigation__header">
                                                            <span className="navigation__dot navigation__dot--active" />
                                                            <h1 className="navigation__title">{ panel[0]?.title }</h1>
                                                            <span onClick={handleShow} className="navigation__arrow">≤</span>
                                                        </div>
                                                        <div className="navigation__body">
                                                            <div className="navigation__img">
                                                                <img src={panelImage[0].url} alt="" />
                                                            </div>
                                                            <button className="navigation__btn" onClick={() => exhibitionRedirect(panel[0].exhibitionPublicUrl)}>ENTER ≥</button>
                                                        </div>
                                                    </div>
                                                    <ul className="source__list">
                                                        {i === openPanel && sources.map(({ sourceTitle }, sourceIndex) => {
                                                            return (
                                                                <React.Fragment key={'sources'+ sourceIndex}>
                                                                    <li className="source__item"
                                                                        onClick={() => handleSourceOpen(sourceIndex)}>
                                                                        <span className="source__dot"/>
                                                                        { sourceTitle }
                                                                        <button className="source__btn">...</button>
                                                                    </li>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </ul>
                                                </>
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                </React.Fragment>
                            )
                        })}
                        <div className="navigation__footer">
                            <button className="btn--prev" onClick={() => handlePreviousPanel()}>
                                <span>≥</span>
                                PREVIOUS PANEL
                            </button>
                            <button className="btn--next" onClick={() => handleNextPanel()}>
                                NEXT PANEL
                                <span>≥</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                openSource !== null && openPanel !== null &&
                <div className="navigation-overlay">
                    <img
                        src={require("../../../src/assets/close-gray.svg")}
                        alt=""
                        className="navigation-overlay__btn"
                        onClick={closeSource}/>

                    <div className="navigation-overlay__img">
                        <img src={
                            exhibitionData[openPanel]
                                ?.sources[openSource]
                                ?.sourceImage[0]?.url
                            }
                            alt=""
                        />
                    </div>
                    <p className="navigation-overlay__text">{ReactHtmlParser(exhibitionData[openPlanet].sources[openSource].sourceText)} </p>
                </div>
            }
            <div className={show ? 'navigation navigation--desktop navigation--active' : 'navigation navigation--desktop'}>
                <div className="navigation__header">
                    <div>
                        <span className="footer__dot"/>
                        <h1 className="navigation__title">{ exhibitionName } <span>{ superScript }</span></h1>
                    </div>
                    <span onClick={handleShow} className="navigation__arrow">≤</span>
                </div>
                <div className="navigation__wrapper">
                    <div className="navigation-col">
                        <div className="constellation">
                            <div>
                                <div className="planet planet--hidden" id="planet"></div>
                                {exhibitionData.map(({ dotPositionX, dotPositionY, sources, panel }, i) => {
                                    let panelUrl = '#';
                                    if(panel.length > 0){
                                        if(panel[0].exhibitionIsActive){
                                            panelUrl = panel[0].exhibitionPublicUrl;
                                        }
                                    }
                                    return (
                                        <React.Fragment key={'exhib2'+i}>
                                            <a
                                                href={panelUrl}
                                                className={'planet' + (i === openPlanet ? ' planet--active' : '') + (i === openPanel  ? ' planet--previous' : '')}
                                                style={{ top: dotPositionY + '%', left: dotPositionX + '%' }}
                                                onMouseEnter={() => setHoveredPlanet(i)}
                                                onMouseLeave={() => setHoveredPlanet(openPlanet ? openPlanet : 0)}
                                                onClick={(e) => placeStars(e, i)}
                                            >
                                                {panel[0].exhibitionIsActive !== false && sources.map(({}, sourceIndex) => {
                                                    return (
                                                        <React.Fragment key={'stars'+ sourceIndex}>
                                                            {i === openPlanet &&
                                                                <>
                                                                    <div
                                                                        className={openSource === sourceIndex ? 'star star--active' : 'star'}
                                                                        onClick={() => setOpenSource(sourceIndex)}
                                                                    />
                                                                    <div className="line" />
                                                                </>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </a>
                                            <div className="line--planet" />
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="navigation-col">
                        {openSource !== null && openPlanet !== null ?
                            <>
                                <div className="navigation-overlay__img">
                                    <img
                                        src={
                                            exhibitionData[openPlanet]
                                                .sources[openSource]
                                                .sourceImage[0].url
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className='navigation-overlay__title-block'>
                                    <p
                                        className="navigation-overlay__text m-b-0"
                                        dangerouslySetInnerHTML={{__html: exhibitionData[openPlanet].sources[openSource].sourceTitle}}
                                    ></p>

                                    <a href={exhibitionData[openPlanet].sources[openSource].sourceUrl} className="navigation-overlay__link m-b-0" target="_blank" rel="noopener noreferrer">
                                        ↗
                                    </a>
                                </div>
                                <p className="navigation-overlay__text m-b-0">-</p>
                                <p
                                    className="navigation-overlay__text"
                                    dangerouslySetInnerHTML={{__html: exhibitionData[openPlanet].sources[openSource].sourceText}}
                                />
                            </>
                            :
                            <>
                            {exhibitionData[hoveredPlanet].panel[0].exhibitionIsActive === true ?
                                <>
                                    <h2 className="source__title">{exhibitionData[hoveredPlanet].panel[0].title}</h2>
                                    <div
                                        className={hoveredPlanet === openPlanet ? 'source__img source__img--active' : 'source__img'}>
                                        <img
                                            src={exhibitionData[hoveredPlanet].panelImage[0].url}
                                            alt=""
                                        />
                                    </div>
                                    <button
                                        className="source__btn"
                                        onClick={() => exhibitionRedirect(exhibitionData[hoveredPlanet].panel[0].exhibitionPublicUrl)}>
                                        ENTER ≥
                                    </button>
                                </>
                                :
                                <>
                                    <h2 className="source__title">PANEL {hoveredPlanet+1}</h2>
                                    <div className="source__description">
                                        {exhibitionData[hoveredPlanet].releaseDate &&
                                        <p>Release date: {exhibitionData[hoveredPlanet].releaseDate}</p>
                                        }
                                        {exhibitionData[hoveredPlanet].event &&
                                        <p>Event: {exhibitionData[hoveredPlanet].event}</p>
                                        }
                                    </div>

                                    <div className="navigation-form__wrapper">
                                        <form
                                            id="form"
                                            action="https://str-ing.us14.list-manage.com/subscribe/post?u=48c50c693a7656495f4f5b6d2&amp;id=cc4cabbcc2"
                                            method="post"
                                            className="navigation-form"
                                        >
                                            <label className="navigation-form__label">Allow us to notify you</label>
                                            <input
                                                name="EMAIL"
                                                placeholder="Enter your E-Mail Adress"
                                                type="email"
                                                className="navigation-form__input"
                                            />
                                        </form>
                                        <button
                                            className="navigation-form__button"
                                            form="form"
                                            type="submit"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </>
                            }

                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navigation;
