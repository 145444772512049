import React, {useRef, useEffect, useMemo, useState, useCallback } from "react"
import { Cursor } from "./Cursor";
import { useSelector, useDispatch } from "react-redux";
import { getMousePosition } from "./helper";
import baadActions from "../../redux/actions";
import styled from "styled-components";
import { CursorVideo } from "./CursorVideo";

const Input = styled.input`
    &::placeholder {
        color: ${props => props.placeholderColor}
    }
`;

export const CursorLocal = ({numberOfGuideUsers}) => {
    const meCursorScaledPos = useSelector((state) => state.cursor);
    const cursorWrapper = useRef(null);
    const meColor = useSelector((state) => state.color);
    const meMessage = useSelector((state) => state.message);
    const localMessage = useSelector((state) => state.message_local);
    const showInput = useSelector((state) => state.showInput);
    const cursorActive = useSelector((state) => state.cursorActive);
    const meUserStatus = useSelector((state) => state.userStatus);

    const dispatch = useDispatch();

    const meCursorPos = getMousePosition(meCursorScaledPos.scaleX, meCursorScaledPos.scaleY );

    const [inputValue, setInputValue] = useState('');
    const [localShowInput, setlocalShowInput] = useState(false);
    const inputEl = useRef(null);

    const onChangeInput = useCallback(e => {
        const inputValue = e.target.value;
        setInputValue(inputValue);
        dispatch(baadActions.clearMessageLocalSaga());
    }, [dispatch]);

    const handleKeyDown = useCallback( (event) => {
        if (event.key === 'Enter') {
            if(inputValue === ""){
                dispatch(baadActions.setTypingIndicator(false));
                dispatch(baadActions.setShowInput(false));
                setlocalShowInput(false);
            } else {
                dispatch(baadActions.setMessageSaga(inputValue));
                dispatch(baadActions.setTypingIndicator(false));
                setInputValue('');
            }

            //dispatch(baadActions.setShowInput(false));
            //setlocalShowInput(false);
        }
    }, [inputValue, dispatch]);

    const handleRightClick = useCallback( (e) => {
        dispatch(baadActions.clearMessageLocalSaga());
        e.preventDefault();
        dispatch(baadActions.setShowInput(true));
        setlocalShowInput(true);
        inputEl.current.focus();
    }, [dispatch]);

    const handleLeftClick = useCallback( (e) => {
        setInputValue('');
        dispatch(baadActions.setShowInput(false));
        setlocalShowInput(false);
    }, [dispatch]);

    const handleKeydown = useCallback( (e) => {
        if (e.code === 'Escape') {
            dispatch(baadActions.setShowInput(false));
            setlocalShowInput(false);
            setInputValue('');
        }
    }, [dispatch]);

    useEffect(() => {
        if(inputValue !== ''){
            dispatch(baadActions.setTypingIndicator(true));
        }
        const typingTimeout = setTimeout(() => {
            dispatch(baadActions.setTypingIndicator(false));
        }, 3000)
        return () => clearTimeout(typingTimeout)

    }, [inputValue, dispatch]);

    useEffect(() => {
        window.addEventListener('contextmenu', handleRightClick);
        window.addEventListener('mousedown', handleLeftClick);
        window.addEventListener('keydown', handleKeydown);

        return () =>{
            window.removeEventListener('contextmenu', handleRightClick);
            window.removeEventListener('mousedown', handleLeftClick);
            window.removeEventListener('keydown', handleKeydown);
        }
    }, []);

    const reverseMessage = meMessage?.slice(0).reverse();
    const reverseMessageLocal = localMessage?.slice(0)?.reverse();

    /*                <div className="guide-dummy" style={{borderColor: meColor?.primary}}>
                    You are live
                </div>*/

    return(

        /*<div className="cursor-local" ref={cursorWrapper} style={{transform: `translate(${meCursorPos?.mouseX}px, ${meCursorPos?.mouseY}px)`}}>*/
        <div className="cursor-local" ref={cursorWrapper} style={{top: `${meCursorPos?.mouseY}px`, left: `${meCursorPos?.mouseX}px`}}>          
            <Cursor color={meColor} active={cursorActive}></Cursor>
            {meUserStatus?.isGuide && 
                <CursorVideo color={meColor} numberOfGuideUsers={numberOfGuideUsers}></CursorVideo>

            }
            {localShowInput &&
                <div className="message-input">
                    <Input
                        autoFocus
                        placeholder="Say something"
                        value={inputValue}
                        onChange={onChangeInput}
                        ref={inputEl}
                        onKeyDown={handleKeyDown}
                        placeholderColor={meColor?.secondary}
                        style={{ backgroundColor: meColor?.primary, color: meColor?.text }}
                    />
                </div>
            }
            {reverseMessage && 
                <div className="messages__wrapper">
                {reverseMessage.map((message, index) =>
                    <div className={'message message_visibility__' + message.status } key={'message_'+index} style={{ backgroundColor: meColor?.primary, color: meColor?.text }}>{message?.text}</div>
                )}
                {!showInput && reverseMessageLocal.map((message, index) =>
                    <div className={'message message-local message_visibility__' + message.status} key={'message_local_'+index}>{message?.text}</div>
                )}
                </div>
            }
        </div>
    )
}