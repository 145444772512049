import { ApolloClient, InMemoryCache } from '@apollo/client';

import constants from './appConstant';


export const createGQLClient = () => {

    const client = new ApolloClient({
        uri: constants.API_URL_FROM_ENV,
        cache: new InMemoryCache(),
      });

    const query = (name, query, variables) => {
        return client
            .query({
                query,
                variables,
                fetchPolicy: 'no-cache',
            })
            .then(({ data }) => data[name]);
    };

    const mutate = (name, mutation, variables) => {
        return client
            .mutate({
                mutation,
                variables,
            })
            .then(({ data }) => data[name]);
    };

    return { query, mutate };
};