import React,{ useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import baadActions from '../../redux/actions';
import './styles/CursorVideo.scss';
import styled from 'styled-components';
import YouTube, { YouTubeProps } from 'react-youtube';

const hexToRgb = (hexInput) => {
    let hex = hexInput ?? false;
    // http://stackoverflow.com/a/5624139
    if(hex){
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
    } else {
        return null;
    }
  };
  
const rgba = (hex, alpha) => {
    const color = hexToRgb(hex);
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};

const youTubeGetID = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
 }

const CursorVideoDiv =  styled.div`
    border: 2px solid ${props => props.mainColor};
`;

const CursorLiveStatusDiv =  styled.div`
    background-color: ${props => props.mainColor};
`;

const CursorUserNumberDiv =  styled.div`
    color: ${props => props.mainColor};
`;

const CursorCoverDiv =  styled.div`
    background-color: ${props => rgba(props.mainColor, 0.25)};
`;

let YouTubePlayer  = false;

export const CursorVideo = ({color, numberOfGuideUsers}) => {
    const dispatch = useDispatch();
    const guideStatus = useSelector((state) => state.guideStatus);
    const meUserStatus = useSelector((state) => state.userStatus);
    const [videoPlayerLoaded, setVideoPlayerLoaded] = useState(false);

    const userJoined = meUserStatus?.joinedGuide;

    /*useEffect(() => {
        if(guideStatus?.guidePresent){
            player = YouTubePlayer('cursor-video');
            player.loadVideoById(youTubeGetID(guideStatus?.guideUrl));
        }
        return () => {
            player = false;
        };
    }, [guideStatus])*/

    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.stopVideo();
        YouTubePlayer = event;
        setVideoPlayerLoaded(true);
    }

    useEffect(() => {
        if(meUserStatus?.joinedGuide && videoPlayerLoaded){
            if(YouTubePlayer){
                YouTubePlayer.target.playVideo();
                if(meUserStatus?.isGuide) {
                    YouTubePlayer.target.setVolume(0);
                } else {
                    YouTubePlayer.target.setVolume(100);
                }
            }
        }else {
            if(YouTubePlayer && videoPlayerLoaded){
                YouTubePlayer.target.stopVideo();
                YouTubePlayer.target.setVolume(0);
            }
        }
        if(meUserStatus?.isGuide && videoPlayerLoaded){
            if(YouTubePlayer){
                YouTubePlayer.target.playVideo();
            }
        }
    }, [meUserStatus, videoPlayerLoaded]);

    useEffect(() => {
        return function cleanup() {
            YouTubePlayer = false;
        };
    }, []);


    return useMemo(
        () => (
        <> {
            <CursorVideoDiv mainColor={color?.primary} className="cursor-guide-video">

            {guideStatus?.guidePresent && guideStatus?.guideUrl && guideStatus?.guideUrl !== '' &&
                <>
                    { (!userJoined && !meUserStatus?.isGuide) && 
                        <CursorCoverDiv mainColor={color?.primary} className="cover"></CursorCoverDiv>
                    }
                    <CursorLiveStatusDiv mainColor={color?.primary} className="live-status">&#8226; live</CursorLiveStatusDiv>
                    <CursorUserNumberDiv mainColor={color?.primary} className="numberOfUsers">{numberOfGuideUsers}</CursorUserNumberDiv>
                    <YouTube className="cursor-video" videoId={youTubeGetID(guideStatus?.guideUrl)} onReady={onPlayerReady}/>
                </>
            }
            </CursorVideoDiv>
            }
        </>
        )
    );
};