import React, { useState, } from 'react';
import { useDispatch } from "react-redux";
import baadActions from '../../redux/actions';
import './aboutPage.scss';

const AboutPage = ({ show, exhibitionName, handleShow, mainData }) => {
    const tabs = ['Text', 'Credits'];
    let [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();

    const handleTabChange = (e, i) => {
        setActiveTab(activeTab = i);
    }

    if(mainData?.length === 0) {
        return null;
    }

    const {
        exhibitionAboutTitle,
        exhibitionMainTextOne,
        exhibitionMainTextTwo,
        exhibitionWriterCredit,
        exhibitionHeadlinerTitle,
        exhibitionHeadlinerEntry,
        exhibitionFreeTextGray,
        exhibitionCredits
    } = mainData;

    return (
        <>
            <div
                className={`${show ? 'about-page__bg about-page__bg--active' : 'about-page__bg'}`}
                onClick={handleShow}
            />
            <div className={`${show ? 'about-page about-page--active' : 'about-page'}`}>
                <div className="about-page__header">
                    <span className="footer__dot inactive"/>
                    <h1 className="about-page__title">
                        { exhibitionName }
                        <span>{ exhibitionAboutTitle }</span>
                    </h1>
                    <span
                        onClick={handleShow}
                        className="about-page__arrow"
                        onMouseOver={() => dispatch(baadActions.setCursorActive(true))}
                        onMouseOut={() => dispatch(baadActions.setCursorActive(false))}
                    >≤</span>
                </div>
                <div className="about-page__wrapper">
                    <div className={activeTab === 0 ? "about-page__col about-page__col--active" : "about-page__col"}>
                        <div>
                            <p className="about-page__text">
                                { exhibitionMainTextOne.map((row,idx)=>(
                                    <span key={idx} className='about-page__text__paragraph'>{row.text}</span>
                                ))}
                            </p>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                            <div>
                            <p className="about-page__text">
                                { exhibitionMainTextTwo.map((row,idx)=>(
                                    <span key={idx} className='about-page__text__paragraph'>{row.text}</span>
                                ))}
                            </p>
                            </div>
                            <p className="about-page__credit">
                                { exhibitionWriterCredit }
                            </p>
                        </div>
                    </div>
                    <div className={activeTab === 1 ? "about-page__col about-page__col--active" : "about-page__col"}>
                        <ul className="about-page__list">
                            <li className="about-page__item about-page__item--primary">{ exhibitionHeadlinerTitle }</li>
                            <li className="about-page__item">{ exhibitionHeadlinerEntry }</li>
                        </ul>
                        {exhibitionCredits && exhibitionCredits.map(({ creditTitle, creditTable }, i) => {
                            return (
                                <React.Fragment key={'credits'+i}>
                                    <ul key={i} className="about-page__list">
                                        <li className="about-page__item about-page__item--primary">{ creditTitle }</li>
                                        <li className="about-page__item">
                                            {creditTable && creditTable.map(({ credit }, i) => {
                                                return (
                                                    <span key={'credits_row'+i}>{ credit } <br /></span>
                                                )
                                            })}
                                        </li>
                                    </ul>
                                </React.Fragment>
                            )
                        })}
                        <ul className="about-page__list">
                            <li></li>
                            <li className="about-page__item about-page__item--light">
                                { exhibitionFreeTextGray.map((row,idx)=>(
                                    <React.Fragment key={'grey_text'+idx}>
                                        <span key={idx}>{ row.text } <br /></span>
                                    </React.Fragment>
                                ))}
                            </li>
                        </ul>
                        <ul className="about-page__list">
                            <li></li>
                            <li className="about-page__item about-page__item--logos">
                                <img
                                    src={require("../../assets/aboutPage/israel-discount-bank-logo.png")}
                                    alt="israel-discount-bank-logo"
                                    width="38"
                                    height="30"
                                    className="about-page__logo about-page__logo--israel"/>

                                <img
                                    src={require("../../assets/aboutPage/artist-logo.png")}
                                    alt="artist-logo"
                                    width="38"
                                    height="15"
                                    className="about-page__logo about-page__logo--artist"/>

                                <img
                                    src={require("../../assets/aboutPage/outset-logo.png")}
                                    alt="outset-logo"
                                    width="38"
                                    height="17"
                                    className="about-page__logo about-page__logo--outset"/>

                                <img
                                    src={require("../../assets/aboutPage/mifal-hapais-logo.png")}
                                    alt="mifal-hapais"
                                    width="30"
                                    height="30"
                                    className="about-page__logo about-page__logo--mifal"/>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="tabs justify-content-around">
                    {tabs.map((data, i) => {
                            return (
                                <span
                                    key={i}
                                    className={i === activeTab ? 'tab tab--active' : 'tab'}
                                    onClick={(e) => handleTabChange(e, i)}
                                >
                                { data }
                            </span>
                            )
                        }
                    )}
                </div>
            </div>
        </>
    );
}
export default AboutPage;
