export const colors = [
    {
        primary: '#2E75E4',
        secondary: '#7AAEFF',
        text: '#D1E3FF',
        inactive: 'rgba(209, 227, 255, 0.8)',
    },
    {
        primary: '#B7F6FF',
        secondary: '#96CAD1',
        text: '#536F73',
        inactive: 'rgba(237, 253, 255, 0.8)',
    },
    {
        primary: '#7EF374',
        secondary: '#65BC5E',
        text: '#3D7539',
        inactive: 'rgba(221, 255, 218, 0.8)',
    },
    {
        primary: '#FFF282',
        secondary: '#C5BB64',
        text: '#75703E',
        inactive: 'rgba(255, 252, 224, 0.8)',
    },
    {
        primary: '#FD996F',
        secondary: '#DA805A',
        text: '#70422E',
        inactive: 'rgba(253, 234, 225, 0.8)',
    },
    {
        primary: '#C9A7EE',
        secondary: '#947AAF',
        text: '#594A69',
        inactive: 'rgba(234, 230, 238, 0.8)',
    },
    {
        primary: '#6090A9',
        secondary: '#7AB6D6',
        text: '#B8E6FF',
        inactive: 'rgba(208, 239, 255, 0.8)',
    },
    {
        primary: '#7B61FF',
        secondary: '#9985FF',
        text: '#D9D2FF',
        inactive: 'rgba(228, 223, 225, 0.8)',
    },
    {
        primary: '#A1C9FF',
        secondary: '#7A99C3',
        text: '#435267',
        inactive: 'rgba(224, 237, 255, 0.8)',
    },
    {
        primary: '#48CFA7',
        secondary: '#308E72',
        text: '#1E5343',
        inactive: 'rgba(205, 255, 240, 0.8)',
    },
    {
        primary: '#FF6C6C',
        secondary: '#D15B5B',
        text: '#6C3131',
        inactive: 'rgba(255, 228, 228, 0.8)',
    },
    {
        primary: '#D9383B',
        secondary: '#F56D6F',
        text: '#FFB7B9',
        inactive: 'rgba(255, 219, 220, 0.8)',
    },
    {
        primary: '#FFA3F0',
        secondary: '#CC6BBC',
        text: '#812672',
        inactive: 'rgba(255, 222, 250, 0.8)',
    },

];