import React, {Component} from 'react';
import {connect} from 'react-redux';
import baadActions from '../../redux/actions/';
import './panelFooter.scss';
import footerLogo from '../../assets/logo.svg';
import galerierudigerschottle from '../../assets/galerierudigerschottle.svg';
import constants from './../../utils/appConstant';
import Typed from "react-typed"

class Footer extends Component {
    constructor(props) {
        super(props);
        var toExhibition = false;
        let exhibitionName = '';
        this.state = {
            pathName: '',
            toExhibition: toExhibition,
            exhibitionName: exhibitionName,
            show: false,
            titleAnimation: false,
            isTitleAnimationEnd: false,
            originSubTitle: '',
	    paramsName:''
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleAnimationEnd = this.handleAnimationEnd.bind(this);
        this.subtitle = React.createRef();
    }

    showModal = () => {
        this.setState({ show: !this.state.show });
    };

    hideModal = () => {
        this.setState({ show: false });
    };
    componentDidMount() {
        this.setState({
            //titleAnimation: localStorage.getItem("titleAnimation")
            titleAnimation: true
        })
    }
    beckToHome = () => {
        this.props.history.push('/');
        this.props.sendFrontUrl('')
    }
    handleRedirectHome = (e) => {
        const urlString = this.props.frontUrl;
        const catIndex = this.props.history.location.state;
        let homeTransitionTime = 200;
        let beforeCategoryTime = 1300;
        if (catIndex === 0 ) {
            homeTransitionTime = 300;
            beforeCategoryTime = 600;
        } else if (catIndex === 1) {
            homeTransitionTime = 300;
            beforeCategoryTime = 800;
        } else if (catIndex === 2) {
            homeTransitionTime = 500;
            beforeCategoryTime = 900;
        }

        if (document.getElementById('showme')) {
            document.getElementById('showme').style.Transition = "all"+ beforeCategoryTime + "ms";
            document.getElementById('artistBarsStyle').style.Transition = "all"+ beforeCategoryTime + "ms";
            document.getElementById('showme').style.marginLeft = 0;
            document.getElementById('artistBarsStyle').style.marginRight = "-200%";
        }
        this.props.sendFrontUrl('');
        if (urlString === '/human-activities' || urlString === '/exhibition') {
            // document.getElementsByClassName('page')[0].classList.add('exit-Right');
            var img = document.getElementById('parentDiv'),
                style = img.currentStyle || window.getComputedStyle(img, false),
                bi = style.backgroundImage.slice(4, -1).replace(/"/g, "");
            setTimeout(() => {
                this.props.history.push({
                    pathname: '/',
                    state: { fromHumanPage: true, backgroundImage: bi }
                })
            }, homeTransitionTime);
        } else {
            setTimeout(() => {
                this.props.history.push({
                    pathname: '/',
                    state: { fromCategoryPage: true }
                })
            }, beforeCategoryTime);
        }
    }
    exhibitionPage = async () => {
        // await new Promise(resolve => {
        //     this.props.getHumanPostDataBySlug(slug, resolve)
        // });
        //document.getElementById('footer_content').style.display = "none";
        // this.props.history.push({
        //     pathname: '/exhibition/1',
        //     // state: { toExhibition:  this.state.toExhibition, exhibitionName: this.state.exhibitionName}
        // });
    }
    handleCursorAnimation = (e) => {
        if (this.state.titleAnimation === true) return;

        const lineCursor = document.getElementById('lineCursor');
        const layoutLock = document.getElementById('layout-lock');

        if (!lineCursor || !layoutLock) return;

        if (e === 0) {
            lineCursor.style.transition = 'left 2.5s linear';
            setTimeout(() => {
                lineCursor.style.left = '600px'
            }, 800);
            setTimeout(() => {
                lineCursor.style.left = '200px'
            }, 2900);
        }
        if (e === 2) {
            layoutLock.style.display = 'none';
            lineCursor.style.transition = 'none';
        }
    }
    handleAnimationEnd = () => {
        const humanActivities = document.getElementById('parentDiv');
        if (!humanActivities) return;
        humanActivities.addEventListener('mousemove', e => {
            this.setState({ isTitleAnimationEnd: true });
        });
        localStorage.setItem("titleAnimation", true);
    }
    
    OnMouseOverFooterTitle = () => {
        this.setState({
            originSubTitle: this.subtitle.current.innerHTML
        })
        this.subtitle.current.innerHTML = 'ABOUT';
        this.props.mouseIn();
        this.props.setCursorActive(true);
    }
    OnMouseOutFooterTitle = () => {
        const originSubTitle = this.state.originSubTitle;
        this.subtitle.current.innerHTML = originSubTitle;
        this.props.mouseOut();
        this.props.setCursorActive(false);
    }
    OnMouseOverFooterDot = () => {
        this.setState({
            originSubTitle: this.subtitle.current.innerHTML
        })
        this.subtitle.current.innerHTML = 'D.S.RICE ARCHIVE';
        this.props.setCursorActive(true);
    }
    OnMouseOutFooterDot = () => {
        const originSubTitle = this.state.originSubTitle;
        this.subtitle.current.innerHTML = originSubTitle;
        this.props.setCursorActive(false);
    }

    render() {
        // console.log("footer props: ", this.props.frontUrl);
        const urlString = this.props.frontUrl;
        // const frontUrl = urlString.replace(" ", " ");
        //const frontUrl = urlString.replace(" ", "_");
        //const imageFrontUrl = frontUrl.replace("/", "");
        let exhibitionName;
        let superScript;

        /*
        //@TODO: unify with Footer & PanelFooter & httpProcessor and apply DRY
        let categoryMap = Object.values(constants.EXHIBITIONS_CATEGORY_IDS);
        let categoryUrls = Object.keys(constants.EXHIBITIONS_CATEGORY_IDS);
        let urlToMapIndex = categoryUrls.indexOf(window.location.pathname);
        let categoryIdToUse = constants.CATEGORY_ID;
        if( urlToMapIndex > -1 ){
            categoryIdToUse = categoryMap[urlToMapIndex];
        }

        this.props.categoriesData && this.props.categoriesData.forEach(category => {
            const {id, name} = category;
            if (categoryIdToUse === id) {
                const exhibitionCategoryName = name.split('-');

                exhibitionName = exhibitionCategoryName[0];
                superScript = exhibitionCategoryName[1];
            }
        })
        */
        superScript = this.props.superScript;
        exhibitionName = this.props.exhibitionName;

        return (
            <>
            <div id="footer_content" className={urlString === '' ? "footer hover-cursor2" : "footer whatever-class"}>
                <div>
                    <div className="footer__title-wrapper">
                        <div className="d-flex align-items-center">
                            <span
                                id="footer__dot"
                                className="footer__dot"
                                onClick={   this.props.paramsName === "davidstormrice"
                      ? ""
                      : this.props.showNavigationPage}
                                onMouseOver={() => this.OnMouseOverFooterDot()}
                                onMouseOut={() => this.OnMouseOutFooterDot()}
                            />
                            {this.state.titleAnimation !== true && window.innerWidth > 375
                                ?
                                <>
                                    {!this.state.isTitleAnimationEnd
                                        ?
                                        <Typed
                                            strings={[
                                                "Hello!",
                                                "Yes, you control the blue line with your mouse.",
                                                "Give it a try."
                                            ]}
                                            startDelay={2000}
                                            typeSpeed={65}
                                            backSpeed={0}
                                            onStringTyped={(e) => this.handleCursorAnimation(e)}
                                            onComplete={() => this.handleAnimationEnd()}
                                            className="footer__title footer__title--animated"
                                        />
                                        :
                                        <Typed
                                            strings={[
                                                'Click the doted lines and browse the exhibition.'
                                            ]}
                                            startDelay={0}
                                            typeSpeed={65}
                                            onStringTyped={null}
                                            className="footer__title footer__title--animated"
                                        />
                                    }
                                </>
                                :
                                <div className="d-flex flex-column flex-lg-row">
                                    {window.innerWidth > 375
                                        ?
                                        <h1
                                            data-js="home-link"
                                            className={`footer__title ${urlString === '' ? 'short' : null}`}
                                            onMouseOver={() => this.OnMouseOverFooterTitle()}
                                            onMouseOut={() => this.OnMouseOutFooterTitle()}
                                            onClick={ this.props.paramsName === "davidstormrice"
                            ? ""
                            : this.props.showAboutPage}
                                            id="footer_logo"
                                        >
                                            { exhibitionName }
                                        </h1>
                                        :
                                        <h1
                                            data-js="home-link"
                                            className="footer__title"
                                            id="footer_logo--mobile"
                                        >
                                            { exhibitionName }
                                        </h1>
                                    }
                                    {window.innerWidth <= 800
                                        ?
                                        <h2 className="footer__subtitle" ref={this.subtitle}>Ilit Azoulay</h2>
                                        :
                                        <h2 className="footer__subtitle" ref={this.subtitle}>{ superScript }</h2>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="d-none d-lg-flex flex-column align-items-end">
                   {this.props.paramsName === "davidstormrice" ? (
              <a
                href="#"
                className={`string-logo ${
                  this.props.paramsName === "davidstormrice"
                    ? "disabledCursor"
                    : ""
                }`}
              >
                <span>powered by str-ing.org</span>
                <img src={footerLogo} alt="" />
              </a>
            ) : (
              <a
                href="https://str-ing.org/"
                target="_blank"
                className={`string-logo`}
              >
                <span>powered by str-ing.org</span>
                <img src={footerLogo} alt="" />
              </a>
            )}
                </div>
                <button
                    id="btn--dots"
                    className="btn--dots"
                    onClick={this.props.showAboutPage}>…</button>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        frontUrl: state.frontUrl,
        //categoriesData: state.categoriesData,
    }
}
const mapDispatchToProps = {
    sendFrontUrl: baadActions.sendFrontUrl,
    mouseIn: baadActions.mousePosIn,
    mouseOut: baadActions.mousePosOut,
    setCursorActive: baadActions.setCursorActive,
    //requestForGetCategoriesData: baadActions.requestForGetCategoriesData,
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
