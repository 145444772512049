import React, { Component } from 'react';
import { Slide } from 'react-slideshow-image';
import { connect } from "react-redux";
// import ReactHtmlParser from 'react-html-parser';

import './home.scss';
import '../panelFooter/panelFooter.scss';

import baadActions from '../../redux/actions/';
// import HomeLink from '../HomeLink/homeLink';

import objectPath from 'object-path'
/*---- slider properties ----*/
const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: true,
    onChange: (oldIndex, newIndex) => {
    }
}
/* end */

class Home extends Component {
    constructor(props) {
        super(props);

        var fromEventPage = false;
        var fromRandomTeaser = false;
        var toHumanPage = false;
        var fromCategoryPage = false;
        var fromHumanPage = false;
        var backgroundImage = '';

        if (props.history.location.state) {
            if (props.history.location.state.fromEventPage) {
                fromEventPage = true;
            }
            if (props.history.location.state.toHumanPage) {
                toHumanPage = true;
            }
            if (props.history.location.state.fromRandomTeaser) {
                fromRandomTeaser = true;
            }
            if (props.history.location.state.fromHumanPage) {
                fromHumanPage = true;
            }
            if (props.history.location.state.fromCategoryPage) {
                fromCategoryPage = true;
            }
            if (props.history.location.state.backgroundImage) {
                backgroundImage = props.history.location.state.backgroundImage;
            }
        }
        this.state = {
            categories: [],
            text: "",
            name: "",
            randomHeight: 0,
            isHovering: false,
            imageLink: '',
            x: 0,
            y: 0,
            fromRandomTeaser: fromRandomTeaser,
            fromEventPage: fromEventPage,
            disableFunction: true,
            secondPage: fromEventPage || toHumanPage || fromCategoryPage,
            enterTransitionDirection: 'enter-Left',
            disableSecondPage: false,
            fromHumanPage: fromHumanPage,
            backgroundImage: backgroundImage,
            isDisable: true,
            isShowArchive: false
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    async componentDidMount() {
        if (this.props.history.location.state) {
            if (this.props.history.location.state.fromEventPage) {
                setTimeout(() => {
                    this.setState({
                        secondPage: false
                    });
                }, 1000);
            }
            else if (this.props.history.location.state.fromCategoryPage) {
                if (document.getElementsByClassName('left-main-div')[0]) {
                    document.getElementsByClassName('left-main-div')[0].style.transitionTimingFunction = "cubic-bezier(.41,.62,.82,.85)";
                }
                if (document.getElementById('imagesSlide')) {
                    document.getElementById('imagesSlide').style.transitionTimingFunction = "cubic-bezier(.41,.62,.82,.85)";
                }
                setTimeout(() => {
                    this.setState({
                        secondPage: false
                    });
                }, 350);
            } 
            else if (this.props.history.location.state.fromHumanPage) {
                if (document.getElementById('imagesSlide')) {
                    document.getElementById('imagesSlide').style.display = "none";
                    setTimeout(() => {
                        document.getElementById('imagesSlide').style.display = "block"
                    }, 710);   
                }
            }
        }
        if (this.props.categories.length === 0) {
            this.props.requestForGetCategories();
        }
        if (this.props.humanData.length === 0) {
            this.props.getHumanData();
        }
        setTimeout(() => {
            this.setState({
                isDisable: false
            });
        }, 1500);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.categories !== this.props.categories) {
            this.setState(
                {
                    categories: this.props.categories
                }
            );
        }
    }

    // clickHomeLink = async () => {
    //     document.getElementsByClassName('page')[0].classList.remove('exit-Left');
    //     document.getElementsByClassName('page')[0].classList.add('exit-Right');
    //
    //     await new Promise(resolve => {
    //         this.props.getPageDataBySlug('random-teaser', resolve)
    //     });
    //
    //     let currentPath = this.props.history.location.pathname;
    //     if (this.state.secondPage) {
    //         this.props.history.push({
    //             pathname: '/random-teaser',
    //             state: {
    //                 beforePath: currentPath,
    //                 fromHumanPage: true
    //             }
    //         });
    //     }
    //     else {
    //         this.props.history.push({
    //             pathname: '/random-teaser',
    //             state: { beforePath: currentPath }
    //         });
    //     }
    // }

    /*--- section page click ----*/
    handleParams = (categoriesId, index) => {
        this.setState(
            {
                isDisable: true
            }
        );

        this.refs['alertContent'].style.opacity = 0;

        document.getElementsByClassName('left-main-div')[0].style.transitionTimingFunction = "cubic-bezier(.23,0,0.4,0)";
        document.getElementById('imagesSlide').style.transitionTimingFunction = "cubic-bezier(.23,0,0.4,0)";
        document.getElementsByClassName('alertStyle')[0].classList.add("change-opacity");

        let homeTransitionTime = 500;
        let totalTransitionTime = 2000;

        if (index === 0) {
            document.getElementsByClassName('left-main-div')[0].style.transitionTimingFunction = "cubic-bezier(.39,.36,.65,.57)";
            document.getElementById('imagesSlide').style.transitionTimingFunction = "cubic-bezier(.39,.36,.65,.57)";
            homeTransitionTime = 400;
            totalTransitionTime = 400;
        }
        else if (index === 1) {
            document.getElementsByClassName('left-main-div')[0].style.transitionTimingFunction = "cubic-bezier(.39,.36,.65,.57)";
            document.getElementById('imagesSlide').style.transitionTimingFunction = "cubic-bezier(.39,.36,.65,.57)";
            homeTransitionTime = 300;
            totalTransitionTime = 600;
        }
        else if (10>= index >= 8) {
            totalTransitionTime = 1600;
        }
        else if (12>= index >= 10) {
            totalTransitionTime = 1800;
        }
        const currentSliderImg = document.getElementById('imagesSlide').querySelector('.active img').src;
        this.setState({
            backgroundImage: currentSliderImg
        });
        this.props.mouseOut();
        this.setState({
            secondPage: true
        });

        let animationLeft = index * 100;
        setTimeout(() => {
            document.getElementsByClassName('slide-home')[0].style.marginLeft = "-" + animationLeft + "%";
        }, homeTransitionTime);

        this.props.requestForGetCategoriesData(categoriesId.id, categoriesId.count);
        setTimeout(() => {
            this.props.history.push('/c/' + categoriesId.slug, index);
            this.props.sendFrontUrl(categoriesId.slug)
        }, totalTransitionTime);
    }
    /* end */

    /*----- Make section title categories ------*/
    exhibition = () => {
        const exhibition = this.props.categories.filter(function(data) {
            return objectPath.get(data, ["meta","term_exhibition_current", "0"] ) === "on";
        })[0];
        return (
            <div className="each-category-item d-flex">
                <p onMouseOver={(e) => { this.handleOnHover(e, exhibition) }}
                    onMouseLeave={() => { this.handleOnLeave() }} 
                    className="text-uppercase"
                    onClick={() => this.humanActivitiesPage(true, exhibition.name)}
                >
                    {exhibition.name}
                </p>
                <p className="px12">/</p>
                <p className="archive" 
                    onClick={this.toggleShowArchive} 
                    onMouseOver={() => { this.props.mouseIn() }}
                    onMouseLeave={() => { this.props.mouseOut() }}
                >Archive</p>
            </div>
        )
    }
    categories = () => {
        return this.props.categories && this.props.categories.map((data, index) => {
            if (objectPath.get(data, ["meta","term_exhibition_current", "0"] ) !== "on") {
                return (
                    <div className="each-category-item archive" onClick={() => this.handleParams(data, index)} key={"category" + index}>
                        <p id={"item-" + index} onMouseOver={(e) => { this.handleOnHover(e, data) }}
                            onMouseLeave={() => { this.handleOnLeave() }}>
                                <span className="item-number">
                                    ({this.makeCategoryItem(objectPath.get(data, ["meta","term_exhibition_start", "0"]))})
                                </span>
                                {data.name}
                        </p>
                    </div>
                )
            }
            return false;
        })
    }
    login = () => {
        return (
            <div className="each-category-item d-flex">
                <p className="pr12">/</p>
                <p onMouseOver={() => { this.props.mouseIn() }}
                   onMouseLeave={() => { this.props.mouseOut() }}
                >Log in</p>
            </div>
        )
    }


    makeCategoryItem = (str) => {
        if (str) {
            return str.split('-')[1] + '.' + str.split('-')[0];
        }
    }

    toggleShowArchive = () => {
        const currentState = this.state.isShowArchive;
        this.setState({
            isShowArchive: !currentState 
        })
    }

    //add the description block of section title categories
    handleOnHover = async(e, data) => {
        if(this.state.isDisable) {
            return false;
        }
        this.props.mouseIn()
        let linkList = document.getElementsByClassName("archive");
        let targetSpan = "";
        if (e.target.tagName === "SPAN") {
            targetSpan = e.target.parentNode.parentNode;
        }
        else {
            targetSpan = e.target.parentNode;
        }

        if (data && data.description) {

            await this.setState({
                text: data.description,
                name: data.name
            });
        }

        const min = document.getElementById('linkdiv').offsetHeight + 25;
        const max = document.getElementById('linkData').offsetHeight - this.refs['alertContent'].offsetHeight - min;
        const rand = min + Math.random() * max;

        this.setState({ randomHeight: rand + "px" });

        for (let i = 0; i < linkList.length; i++) {
            if (linkList[i].style) {
                linkList[i].style.opacity = 0.55;
            }
        }
        targetSpan.style.opacity = 1;
        this.refs['alertContent'].style.opacity = 1;
    }

    handleOnLeave = () => {
        this.props.mouseOut();
        this.refs['alertContent'].style.opacity = 0;
        let linkList = document.getElementsByClassName("archive");

        this.setState({
            text: "",
            name: ""
        })

        for (let i = 0; i < linkList.length; i++) {
            if (linkList[i].style) {
                linkList[i].style.opacity = 1;
            }
        }
    }
    /* end */


    /*---- human activity page js -----*/
    humanActivitiesPage = async (toExhibition, exhibitionName) => {
        this.timer = setTimeout(() => {
            this.setState({ disableFunction: false });
        }, 1500);
        document.getElementsByClassName('page')[0].classList.remove('exit-Left');
        document.getElementsByClassName('left-main-div')[0].style.transitionTimingFunction = "cubic-bezier(.35,0,.8,.5)";
        document.getElementById('imagesSlide').style.transitionTimingFunction = "cubic-bezier(.35,0,.8,.5)";
        const currentSliderImg = document.getElementById('imagesSlide').querySelector('.active img').src;
        this.setState({
            backgroundImage: currentSliderImg
        });
        this.props.mouseOut();
        if (toExhibition) {
            setTimeout(() => {
                this.props.history.push({
                    pathname: '/exhibition',
                    state: { backgroundImage: currentSliderImg, toExhibition:  toExhibition, exhibitionName: exhibitionName}
                });
            }, 400);
        } else {
            setTimeout(() => {
                this.props.history.push({
                    pathname: '/human-activities',
                    state: { backgroundImage: currentSliderImg, toExhibition:  toExhibition, exhibitionName: exhibitionName}
                });
            }, 400);
        }
        
        this.setState({
            secondPage: true
        });
    }

    // setBg = (bgUrl) => {
    //     this.setState({
    //         backgroundImage: bgUrl
    //     });
    // };

    render() {
        if (this.props.humanData.length === 0) {
            return null;
        }

        const { backgroundImage } = this.state;
        var humanData = this.props.humanData;
        let temp = humanData[0];
        if (backgroundImage !== '') {
            humanData.forEach((data, index) => {
                if (data.acf.image_url === backgroundImage) {
                    humanData[0] = data;
                    humanData[index] = temp;
                }
            })
        }

        let enterTransitionDirection = this.state.enterTransitionDirection;
        if (this.state.fromRandomTeaser) {
            enterTransitionDirection = 'enter-Right';
        }
        else if (this.state.fromEventPage) {
            enterTransitionDirection = 'enter-Down';
        }
        let bgUrl = "";
        if(this.state.backgroundImage === '') {
            bgUrl = humanData[0].acf.image_url;
            // this.setBg(bgUrl);
        } else {
            bgUrl = this.state.backgroundImage;
        }
        return (
            <div className={"whatever-class home-page page exit-Left " + enterTransitionDirection} id="mainHome">
                {/*<HomeLink history={this.props.history} clickHomeLink={() => this.clickHomeLink()}></HomeLink>*/}
                <div className="headerDiv">
                    <div className="main-border position-relative animation-content">
                        <div className="slide-home">
                            <div id="linkData" className={"left-main-div main-part " + (this.state.secondPage ? 'secondLinkDiv' : 'FistLinkDiv')}>
                                <div id="linkdiv" className="category-part">
                                    {this.exhibition()}
                                    {
                                        this.state.isShowArchive ?
                                        this.categories() : ''
                                    }
                                    {this.login()}
                                </div>

                                <div className="alertStyle" style={{ top: this.state.randomHeight }} ref="alertContent">
                                    <div className="category-desctiption__name">
                                        {this.state.name}
                                    </div>
                                    <div className="category-desctiption__text">
                                        {this.state.text}
                                    </div>
                                </div>
                            </div>
                            <div id="imagesSlide" className={"sliderStyle h-100 human-part " + (this.state.secondPage ? 'secondImagesSlide' : 'FistImagesSlide')}
                                onMouseOver={this.props.mouseIn}
                                onMouseOut={this.props.mouseOut}
                            >
                                <div className="h-100" onClick={() => this.humanActivitiesPage(false, "")}>
                                    {
                                        !this.state.secondPage ?
                                            <Slide {...properties}>
                                                {
                                                    humanData.map((data, i) => {
                                                        return (
                                                            <div key={"slide" + i}>
                                                                <img className="imgStyle" id="imageSlideOne" src={data.acf.image_url} alt="" />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Slide>
                                            :
                                            <div id="fullImg" className="h-100">
                                                <div id="parentDiv" className="d-flex humanActivitiesMainDiv" style={{ backgroundImage: `url(${bgUrl})` }}>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            this.props.categories && this.props.categories.map((data, index) => {
                                let categoryNum = data.count;
                                let lineWidth = "10px";
                                let verticalLines = [];
                                if (categoryNum > 45) {
                                    lineWidth = "1px";
                                }
                                else if (categoryNum > 40) {
                                    lineWidth = "2px";
                                }
                                else if (categoryNum > 30) {
                                    lineWidth = "3px";
                                }
                                else if (categoryNum > 25) {
                                    lineWidth = "4px";
                                }
                                else if (categoryNum > 20) {
                                    lineWidth = "5px";
                                }
                                else if (categoryNum > 15) {
                                    lineWidth = "6px";
                                }
                                else if (categoryNum > 10) {
                                    lineWidth = "7px";
                                }
                                else if (categoryNum > 16) {
                                    lineWidth = "8px";
                                }
                                else if (categoryNum > 3) {
                                    lineWidth = "10px";
                                }

                                for (let i = 0; i < categoryNum; i++) {
                                    verticalLines.push(
                                        <div className="vertical-line tip" style={{ borderWidth: lineWidth }} key={"line" + i}>
                                        </div>
                                    )
                                }
                                return (
                                    <div className="lines" style={{ borderWidth: lineWidth }} key={"lines" + index} id={data.id}>
                                        <div className="artistBarsStyle">
                                            {verticalLines}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        categories: state.categoriesData,
        humanData: state.humanData
    }
}

const mapDispatchToProps = {
    requestForGetCategories: baadActions.requestForGetCategories,
    requestForGetCategoriesData: baadActions.requestForGetCategoriesData,
    getPageDataBySlug: baadActions.getPageDataBySlug,
    getPostDataBySlug: baadActions.getPostDataBySlug,
    getHumanData: baadActions.getHumanData,
    sendFrontUrl: baadActions.sendFrontUrl,
    mouseIn: baadActions.mousePosIn,
    mouseOut: baadActions.mousePosOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);

