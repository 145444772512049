import React,{ useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import baadActions from '../../redux/actions';
import './styles/CursorGuideStatus.scss';

export const CursorGuideStatus = () => {
    const dispatch = useDispatch();
    const guideStatus = useSelector((state) => state.guideStatus);
    const meUserStatus = useSelector((state) => state.userStatus);


    const handleJoinClick = () => {
        dispatch(baadActions.setUserStatus({isGuide: meUserStatus.isGuide, joinedGuide: true}));
    }

    const handleLeaveClick = () => {
        dispatch(baadActions.setUserStatus({isGuide: meUserStatus.isGuide, joinedGuide: false}));
    }

    if(!guideStatus?.guidePresent && !meUserStatus?.isGuide){
        return null;
    }
    return (
        <>
            {!meUserStatus?.isGuide && 
                <div className="cursor-guide-status">
                    <div className="guide__left">Live Guided Tour in Progress</div>
                    {!meUserStatus?.joinedGuide &&
                        <div
                            className="guide__right"
                            onClick={handleJoinClick}
                            onMouseOver={() => dispatch(baadActions.setCursorActive(true))}
                            onMouseOut={() => dispatch(baadActions.setCursorActive(false))}
                        >Click to join</div>
                    }
                    {meUserStatus?.joinedGuide &&
                        <div
                            className="guide__right"
                            onClick={handleLeaveClick}
                            onMouseOver={() => dispatch(baadActions.setCursorActive(true))}
                            onMouseOut={() => dispatch(baadActions.setCursorActive(false))}                        
                        >Mute Live</div>}
                </div>
            }
            {meUserStatus?.isGuide && 
                <div className='cursor-guide-status'>
                    <div className="guide__left">You are live</div>
                    <div className='guide__right'>Hi</div>
                </div>
            }
        </>
    );
};