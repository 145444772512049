import React, { Component } from 'react';
import './MobileOverlay.scss';

export default class MobileOverlay extends Component {
    constructor(props) {
        super(props);
        this.mobileOverlay = React.createRef();
        localStorage.setItem('mobileOverlay', 'false');
    }

    closeMobileOverlay = () => {
        this.mobileOverlay.current.style.display = "none";
    }

    render() {
        return (
            <>
                <div
                    ref={this.mobileOverlay}
                    className="mobile-overlay"
                    onClick={() => this.closeMobileOverlay()}
                >
                    <i className="fa fa-arrows">&lt; &gt;</i>
                    <p className="mobile-overlay__text">Aim at the blue line<br /> Swipe left and right</p>
                    <span className="span fa fa-circle">
                        <img src={require("../../assets/arrow-blue.svg")} alt="arrow-blue"/>
                    </span>
                    <p className="mobile-overlay__text">Press arrow to enter page</p>
                </div>
            </>
        )
    }
}
