 const constants = {
    CATEGORIES_DATA:'CATEGORIES_DATA',
    POST_DATA:'POST_DATA',
    PAGE_DATA: 'PAGE_DATA',
    HUMAN_DATA: 'HUMAN_DATA',
    HUMAN_POST_DATA: 'HUMAN_POST_DATA',
    EXHIBITIONS_DATA: 'EXHIBITIONS_DATA',
    QUEENDOM_DATA: 'QUEENDOM_DATA',
    MAIN_DATA: 'MAIN_DATA',
    PANEL_DATA: 'PANEL_DATA',
    CONTRIBUTION_DATA: 'CONTRIBUTION_DATA',
    ROOM_DATA: 'ROOM_DATA',
    MESSAGE_DATA: 'MESSAGE_DATA',
}
export default constants;
