import React, { Component } from "react";
import {Col, Row} from 'react-bootstrap';
import {connect} from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import ImageGallery from 'react-image-gallery';
import './humanPost.scss';
import 'react-image-gallery/styles/scss/image-gallery.scss';

import baadActions from '../../redux/actions/';
// import HomeLink from '../HomeLink/homeLink';
import PanelFooter from '../panelFooter/panelFooter';
import logo from "../../assets/logo.svg";
import HumanPostForm from "../../components/HumanPostForm/humanPostForm";
import { HelmetHeader } from "../../components/HelmetHeader/HelmetHeader";
import ScrollContainer from 'react-indiana-drag-scroll'
import constants from "../../utils/appConstant";
import { Redirect } from "react-router";
import ReactPlayer from 'react-player';
import { Viewer } from "react-iiif-image-viewer";
import { findDOMNode } from "react-dom";


class humanPost extends Component {

    constructor(props) {
        super(props);
        this.showColumn = this.showColumn.bind(this);
        this.openContactForm = this.openContactForm.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.enlargePhoto = this.enlargePhoto.bind(this);
        this.openGalleryDrawer = this.openGalleryDrawer.bind(this);
        this.closeGalleryDrawer = this.closeGalleryDrawer.bind(this);
        this.copyUrlToClipboard = this.copyUrlToClipboard.bind(this);
        this.playVideo = this.playVideo.bind(this);
        this.onToggleMute = this.onToggleMute.bind(this);
        this.ref = React.createRef();
        this.galleryBig = React.createRef()
        this.fullscreenVideo = React.createRef();
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            fromRandomTeaser: false,
            activeIndex: 0,
            activeStep: 0,
            activeTab: 0,
            buyWorkDrawer: false,
            isContactOpen: false,
            isVideo: false,
            template: '',
            isEnlarged: false,
            isPlaying: true,
            muted: true,
            width: window.innerWidth
        }
        this.props.setCursorActive(false)
    }

    static getDerivedStateFromProps(props, state) {
        if(props?.history.location.state){
            if(props?.history.location.state.fromRandomTeaser){
                state.fromRandomTeaser = true;
            }
        }
        if (props?.contributionData) {
            if(props?.contributionData.template === 'fullscreenVideo'){
                state.isEnlarged = true;
            }
        }
        return null;
    }

    componentDidMount() {
        this.props.mouseOut();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.setState({
            isPlaying: false,
        })

        if (window.innerWidth < 800) {
            this.setState({
                muted: false
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    async componentDidUpdate(prevProps) {

        if( this.props.userStatus?.joinedGuide && (this.props.guideRoom !== prevProps.guideRoom) ) {
            let newGuideRoom = this.props.guideRoom ?? false
            //console.log('room changed to:' + newGuideRoom);
            if(newGuideRoom && newGuideRoom.includes('/panel')){
                this.handleRedirectHome(false)
            }
        }

        if ( this.props.history.action === "POP" && String(prevProps.contributionData) === "") {
            let paramsName = this.props.history.location.pathname.slice(2).replace(/^\//, '');
            this.props.getContributionData(paramsName);
            /*await new Promise(resolve => {
                this.props.getHumanPostDataBySlug(paramsName, resolve)
            });*/
        }

        if ( this.props.history.action === "POP" && String(prevProps.mainData) === "") {
            this.props.getMainData();
        }

        var hoverSentence = document.querySelectorAll(".image-src");
        for (let i=0; i<hoverSentence.length; i++){
            if(hoverSentence[i]){
                let dataJs = hoverSentence[i].attributes['data-js'].value;
                hoverSentence[i].addEventListener('mouseover', (e)=>{
                    this.props.mouseIn();
                    let imageUrl = 'https://baadgallery.codingmice.com/' + e.target.attributes['data-image-url'].value;

                    if(dataJs === "image-title-src"){
                        let img = document.createElement('img');
                        img.src =  imageUrl;
                        img.className = 'hover-image';
                        document.querySelector(".hoverImage-position").appendChild(img);
                    } else {
                        let img = document.createElement('img');
                        img.src =  imageUrl;
                        img.className = 'hover-image';
                        document.querySelector(".further-hoverImage-position").appendChild(img);
                    }

                })
                hoverSentence[i].addEventListener('mouseout', ()=>{
                    this.props.mouseOut();
                    if(dataJs === "image-title-src"){
                        document.querySelector(".hover-imgPart").innerHTML = "";
                    }else{
                        document.querySelector(".hover-furtherimgPart").innerHTML = "";
                    }
                })
            }
        }
        var footnote = document.querySelectorAll(".footnote");
        for (let i=0; i<footnote.length; i++){
            if(footnote[i]){
                footnote[i].addEventListener('mouseover', (e)=>{
                    this.props.mouseIn();
                    let singleFootNote = e.target.attributes['data-note'].value;
                    const classList = document.getElementsByClassName("span_class");
                    const classLength = classList.length;
                    if (classLength === 0) {
                        let span = document.createElement('span');
                        span.className = 'span_class'
                        let posTop = e.pageY;
                        var count =i+1;
                        span.style.cssText = "top: "+posTop+"px; position: relative;";
                        span.innerHTML = "<span class='span_count'>"+count+"</span> "+singleFootNote;
                        document.querySelector(".hover-footNote").appendChild(span);
                    }

                })
                footnote[i].addEventListener('mouseout', ()=>{
                    this.props.mouseOut();
                    document.querySelector(".footDiv").innerHTML = "";
                })
            }
        }

        if(this.props.contributionData && this.props.contributionData.length !== 0){
            let template = this.props.contributionData.template;
            const panelTwo = document.getElementById('panels-item--two');
            const contactPanel = document.getElementById('panels-item--three');
            if (template === 'video3Smallfont') {
                template = 'video2Bigfont'
            }
            if (template === 'video1') {
                contactPanel.style.left = '0';
                contactPanel.style.maxWidth = '100%';
            }
            if (template === 'video2' || template === 'gallery-big') {
                panelTwo.style.left = '0';
                panelTwo.style.margin = 'unset';
                contactPanel.classList.add('video-2--three')
            }
            if (panelTwo) {
                panelTwo.style.zIndex = '1';
            }

            const headingForRedirect = document.querySelector('h2.headingForRedirect')
            headingForRedirect.textContent = this.props.contributionData.title;

            const scrollContainer = document.querySelector('.scroll-container');
            if (scrollContainer && this.props.contributionData.image_position) {
                const [positionX, positionY] = this.props.contributionData.image_position.split(',');
                scrollContainer.scrollLeft = parseInt(positionX);
                scrollContainer.scrollTop = parseInt(positionY);
            }
        }
    }
    // clickHomeLink = async() => {
    //     document.getElementsByClassName('page')[0].classList.remove('exit-Down');
    //     document.getElementsByClassName('page')[0].classList.add('exit-Right');
    //
    //     await new Promise(resolve => {
    //         this.props.getPageDataBySlug('random-teaser', resolve);
    //     });
    //     let currentPath = this.props.history.location.pathname;
    //     this.props.history.push({
    //         pathname: constants.INTRO_URL === 'intro' ? "/gallery" : '/human-activities',
    //         state: {beforePath: currentPath}
    //     });
    // }

    handleRedirectHome = (e) => {
        // document.getElementsByClassName('page')[0].classList.remove('exit-Down');
        // document.getElementsByClassName('page')[0].classList.add('exit-Right');
        if(e){
            e.preventDefault();
        } 
        //find my home
        const parentPanel = this.props?.mainData?.children.find(panel => {
            return panel.exhibitionContributions.some((contribution) => contribution.id == this.props?.contributionData?.id);
        });

        const prevPath = this.props.history.location.state;
        this.props.history.push({
            pathname: prevPath && prevPath.prevPath ? this.props.history.location.state.prevPath : parentPanel.exhibitionPublicUrl,
            state: {fromEventPage: true}
        });
    }

    handleRedirectHuman = () => {
        document.getElementsByClassName('headerDiv')[0].style.padding = "0px 10px 58px";
        if (this.props.history.location.state.toExhibition) {
            this.props.history.push({
                pathname: constants.INTRO_URL === 'intro' ? "/gallery" : '/human-activities',
                state: {
                    fromEventPage: true,
                    toExhibition:  this.props.history.location.state.toExhibition,
                    exhibitionName: this.props.history.location.state.exhibitionName
                }
            })
        } else {
            this.props.history.push({
                pathname: constants.INTRO_URL === 'intro' ? "/gallery" : '/human-activities',
                state: {
                    fromEventPage: true,
                    toExhibition:  false,
                    exhibitionName: '',
                }
            })
        }
    }

    handleRedirectArtist = async(slug) => {
        document.getElementsByClassName('page')[0].classList.remove('exit-Down');
        document.getElementsByClassName('page')[0].classList.add('exit-Up');
        this.props.getContributionData(slug.replace(/^\//, ''));
        /*await new Promise(resolve => {
            this.props.getPostDataBySlug(slug, resolve);
        });*/
        this.props.history.push({
            pathname: '/'+slug,
            // state: {fromEventPage: true}
        });
    }

    showColumn = (data, e) => {
        const panels = document.getElementsByClassName("panels-item");

        if(data.currentTarget) {
            [].forEach.call(panels, function(el) {
                el.classList.remove( "action-panel" );
            });

            for(let i=0; i < panels.length; i++) {
                if (panels[i].id === data.currentTarget.id) {
                    panels[i].classList.add("action-panel");
                    panels[1].setAttribute("style", "z-index: 2");
                }
            }
        }
    }

    deleteImage(item,i){
        document.getElementById('image_div'+i).classList.add("hide-item");
        const showDom = this.refs.closeImage;
        showDom.classList.add("show-item");
        this.handler = setTimeout(function(){
            showDom.classList.remove('show-item');
        }, 50)
    }

    changeSlider(i) {
        this.setState({
            activeIndex: i
        });
    }

    openContactForm() {
        this.setState({
            isContactOpen: !this.state.isContactOpen
        })
    }

    onFormSubmit = (newActiveStep) => {
        this.setState({
            activeStep: newActiveStep
        });
    };

    changeTitle(e) {
        const headingForRedirect = document.querySelector('h2.headingForRedirect')
        const targetID = e.currentTarget.id;

        if (!this.state.isContactOpen && window.innerWidth > 375) {
            if (targetID === 'first-column') {
                headingForRedirect.textContent = this.props.contributionData?.artistName
            }
            if (targetID === 'panels-item--one') {
                headingForRedirect.textContent = this.props.contributionData?.columnOneHeader
            }
            if (targetID === 'panels-item--two') {
                headingForRedirect.textContent = this.props.contributionData?.columnTwoHeader
            }
            if (targetID === 'panels-item--three') {
                headingForRedirect.textContent = 'INFO'
            }
        }
    }

    handleTabChange(e, i) {
        this.setState({
            activeTab: i
        })
    }

    toggleScrolling = (isEnable) => {
        if (isEnable) {
            window.addEventListener('mousemove', this.onMouseMove);
            window.addEventListener('mouseup', this.onMouseUp);
        } else {
            window.removeEventListener('mousemove', this.onMouseMove);
        }
    };

    enlargePhoto() {
        const firstSecondColumn = document.querySelector('.first-second-column');
        const firstColumn = document.getElementById('first-column');
        const galleryBigImg = document.querySelector('.gallery-big__img');
        this.setState({
            isEnlarged: !this.state.isEnlarged
        })

        if (window.innerWidth > 375) {
            if (!this.state.isEnlarged) {
                firstSecondColumn.style.transform = "translateX(1000px)"
                setTimeout(() => {
                    firstSecondColumn.style.display = "none"
                    firstColumn.style.maxWidth = "100%"
                    firstColumn.style.flex = "1"
                }, 1000);
                setTimeout(() => {
                    galleryBigImg.style.position = "unset";
                }, 1500);
            } else {
                firstSecondColumn.style.display = "block"
                firstColumn.style.maxWidth = "50%"
                galleryBigImg.style.position = "absolute";
                setTimeout(() => {
                    firstColumn.style.flex = "0 0 50%"
                    firstSecondColumn.style.transform = "translateX(0)"
                }, 500);
            }
        }
    }

    openGalleryDrawer() {
        this.setState({
            isEnlarged: !this.state.isEnlarged
        })

        this.setState({
            isPlaying: false,
        })

        let videoRightPanel = document.querySelector('.gallery-zoom__drawer');
        if(videoRightPanel) {
            videoRightPanel.style.transform = "translateX(0)";
            videoRightPanel.classList.add("gallery-zoom__drawer--active")
        }

        let playbutton = document.querySelector('.play-btn-js');
        if(playbutton) {
            playbutton.style.display = "block";
            playbutton.style.left = "33%";
        }

        let pausebutton = document.querySelector('.pause-btn-js');
        if(pausebutton) {
            pausebutton.style.display = "none";
        }
    }

    closeGalleryDrawer() {
        this.setState({
            isEnlarged: !this.state.isEnlarged
        })

        this.setState({
            isPlaying: !this.state.isPlaying,
        })

        let videoRightPanel = document.querySelector('.gallery-zoom__drawer');
        if(videoRightPanel) {
            videoRightPanel.style.transform = "";
            videoRightPanel.classList.remove("gallery-zoom__drawer--active")
        }
    }

    playVideo() {
        this.setState({
            isPlaying: true
        })

        let playbutton = document.querySelector('.play-btn-js');
        if(playbutton) {
            playbutton.style.display = "none";
        }

        // let pausebutton = document.querySelector('.pause-btn-js');
        // if(pausebutton) {
        //     pausebutton.style.display = "block";
        // }

        const videoRightPanel = document.querySelector('.gallery-zoom__drawer--active');
        if(videoRightPanel) {
            videoRightPanel.style.transform = "translateX(100%)";
            videoRightPanel.classList.remove("gallery-zoom__drawer--active")
        }
    }

    pauseVideo() {
        this.setState({
            isPlaying: false
        })

        let playbutton = document.querySelector('.play-btn-js');
        if(playbutton) {
            playbutton.style.display = "block";
            playbutton.style.left = "50%";
        }
        let pausebutton = document.querySelector('.pause-btn-js');
        if(pausebutton) {
            pausebutton.style.display = "none";
        }
    }

    togglePauseBtn() {

    }

    onToggleMute() {
        this.setState({
                muted: !this.state.muted
        })
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
    }

    column3() {
        return (
            <>
                <HumanPostForm
                    isContactOpen={this.state.isContactOpen}
                    openContactForm={this.openContactForm}
                    humanPostData={this.props.humanPostData}
                    contributionData={this.props.contributionData}
                    handlePostSubmit={this.onFormSubmit}
                    activeStep={this.state.activeStep}/>

                {!this.state.isContactOpen &&
                <>
                    <div className="column3-header">
                        {this.props.contributionData.title} <br/>
                        {this.props.contributionData.artistName && this.props.contributionData.artistName}
                    </div>
                    <span>—</span>
                    <div className="column3-description">
                        {this.props.contributionData?.buy_column_description &&
                        <p className="column3-description__item">
                            {this.props.contributionData?.buy_column_description}
                        </p>}
                        {this.props.contributionData?.technique &&
                        <p className="column3-description__item">
                            Technique: {this.props.contributionData?.technique}
                        </p>
                        }
                        {this.props.contributionData?.fileType &&
                        <p className="column3-description__item">
                            File Type: {this.props.contributionData?.fileType}
                        </p>
                        }
                        {this.props.contributionData?.editionNumber &&
                        <p className="column3-description__item">
                            EDITION NUMBER: {this.propscontributionData?.editionNumber}
                        </p>
                        }
                        {this.props.contributionData?.collectorReceives &&
                        <p className="column3-description__item">
                            Collector
                            Receives: {this.props.contributionData?.collectorReceives}
                        </p>
                        }
                        {this.props.contributionData?.price && this.props.contributionData?.readingText ?
                            <span>—</span>
                            :
                            null
                        }
                        {this.props.contributionData?.readingText &&
                        <>
                            <p className="column3-description__item column3-description__item--lowercase">
                                {ReactHtmlParser(this.props.contributionData?.readingText)}
                            </p>
                        </>
                        }
                    </div>
                    <div className="mb20">
                        {
                            this.props.contributionData.participating_artists &&
                            <h2>Participating artists</h2>
                        }
                    </div>

                    {this.props.contributionData?.price &&
                    <>
                        <span>—</span>
                        <p className="panels-item__nft-price">
                            Price: <span className="text-lowercase">{this.props.contributionData?.price}</span>
                        </p>

                        <a onClick={this.openContactForm} className="btn--link">
                            Purchase ≥
                        </a>
                    </>}
                </>}
            </>
        )
    }

    copyUrlToClipboard() {
        navigator.clipboard.writeText(window.location.href);
        const shareMessage = document.querySelector('.share-message__wrapper');
        shareMessage.style.visibility = 'visible';

        setTimeout(() => {
            shareMessage.style.visibility = 'hidden';
        }, 2000);
    }

    onClickFullscreen = () => {
        const video = findDOMNode(this.fullscreenVideo.current);

        if (video.requestFullScreen) {
            video.requestFullScreen();
        } else if (video.mozRequestFullScreen) {
            video.mozRequestFullScreen();
        } else if (video.webkitRequestFullScreen) {
            video.webkitRequestFullScreen();
        }
    }

    updateProgressBar = (info) => {

        let progressIndicator = document.querySelector('.fullscreen-video__progress');
        progressIndicator.style.width = info.played*100 + "%";

        let playedMinutes = Math.floor(info.playedSeconds/60);
        let playedMinutesText = '';

        if(playedMinutes<10){
            playedMinutesText = "0"+playedMinutes;
        } else {
            playedMinutesText = playedMinutes;
        }

        let playedSeconds = Math.floor(info.playedSeconds) - playedMinutes*60;
        let playedSecondsText = '';
        if(playedSeconds<10){
            playedSecondsText = "0"+playedSeconds;
        } else {
            playedSecondsText = playedSeconds;
        }

        let currentTimeSeconds = document.querySelector('.fullscreen-video__timeInfoCurrent');
        currentTimeSeconds.innerHTML = playedMinutesText+":"+playedSecondsText;
    }

    render() {
        console.log(this.props);
        if(!this.props.contributionData) {
            return <Redirect to='/' />
        }
        if(this.props.contributionData.length === 0){
            return null
        }

        let template = this.props.contributionData.template;
        const tabs = template === 'video1' ? ['Media', 'Info']  : ['Media', 'Text', 'Info'];

        if (this.props.contributionData?.imageskiller) {
            this.images = this.props.contributionData?.imageskiller.map((data, i) => {
                return {
                    original: data['images-killer-image'].url,
                    originalTitle: data['images-killer-image'].title,
                    description: data['images-killer-image'].title,
                    thumbnailTitle: data['images-killer-image'].title,
                    originalAlt: data['images-killer-image'].alt,
                    thumbnailAlt: data['images-killer-image'].alt,
                }
            })
        }

        let seoTitle = this.props?.contributionData?.seo?.title;
        let seoImageUrl = this.props?.contributionData?.seo?.social?.facebook?.image?.url;
        let seoSlug = this.props?.match?.url;
        let seoDescription = this.props?.contributionData?.seo?.description;

        if (template === 'video3Smallfont') {
            template = 'video2Bigfont'
        }

        const column3 = this.column3();

        if (template === 'galleryZoom') {
            const tabs = ['Media', 'Info'];

            return (
                <div
                    className={"whatever-class humanPost-Page page exit-Down " + (this.state.fromRandomTeaser ? 'enter-Right' : 'enter-Up')}>
                    {/*<HomeLink history={this.props.history} clickHomeLink={() => this.clickHomeLink()}/>*/}

                    <HelmetHeader title={seoTitle} seoSlug={seoSlug} description={seoDescription} imageUrl={seoImageUrl}></HelmetHeader>

                    <div className="headerDiv headerDiv-artist">
                        <div className="main-border">
                            <div
                                className={this.state.isEnlarged ? 'gallery-zoom__wrapper gallery-zoom__wrapper--active' : 'gallery-zoom__wrapper'}>
                                <div className={this.state.activeTab === 0 ? 'gallery-zoom--active' : 'gallery-zoom'}
                                     onClick={this.state.isEnlarged ? this.closeGalleryDrawer : null}>
                                    {this.props.contributionData.templateGallyerZoomViewerImageMetafieldUrl &&
                                        <Viewer
                                            iiifUrl={this.props.contributionData.templateGallyerZoomViewerImageMetafieldUrl}
                                            width="100%"
                                            height="100%"
                                            defaultZoom={this.props.contributionData.templateGalleryZoomZoomLevel}
                                        />
                                    }

                                    <img src={require("../../assets/setting-btn.svg")} alt="" onClick={() => {this.openGalleryDrawer();}} className="gallery-zoom__btn" />
                                </div>
                                <div
                                    className={this.state.isEnlarged || this.state.activeTab === 1 ? 'gallery-zoom__drawer gallery-zoom__drawer--active' : 'gallery-zoom__drawer'}>
                                    {column3}
                                </div>
                            </div>
                            <div className="tabs justify-content-around">
                                {tabs.map((data, i) => {
                                        return (
                                            <span
                                                key={i}
                                                className={i === this.state.activeTab ? 'tab tab--active' : 'tab'}
                                                onClick={(e) => this.handleTabChange(e, i)}>
                                                {data}
                                            </span>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>

                    <PanelFooter
                        contributionId={this.props?.contributionData.id}
                        history={this.props.history}
                        urlPath={this.props.contributionData.title}
                        isHumanPostPage={true}
                        isContactOpen={this.state.isContactOpen}
                        handler={this.openContactForm}
                        onClickLogo={(e) => this.handleRedirectHome(e)}
                        activeFormStep={this.state.activeStep}
                        onClikHeaderCategory={() => this.handleRedirectHuman()}>
                    </PanelFooter>
                </div>
            )
        }

        if (template === 'animatedQuote') {
            const SENTENCE_DELAY = 1200;
            setTimeout(() => {
                let sentencesForFading = document.querySelectorAll('.text-template__text');
                sentencesForFading.forEach(sentence=>{
                    sentence.innerHTML = sentence.textContent.split(' ').map(word=>'<span class="faded-word">'+word+'</span>').join(' ');
                    sentence.style.color="#000";
                });

                let wordsForFading = document.querySelectorAll('.faded-word');

                wordsForFading.forEach(word=>{
                    word.addEventListener('transitionend', startNextWordAnimation);
                });
                startSentence(document.querySelector('.text-template__text'));
              }, "500")
            
            function startNextWordAnimation(e){
                let nextWord = e.target.nextElementSibling;
                if( nextWord ){
                    nextWord.classList.add('faded-activated');
                } else {
                    let nextSentence = e.target.parentElement.nextElementSibling;
                    startSentence(nextSentence);
                }
            }

            function startSentence(sentenceElement){
                if(!sentenceElement){
                    return;
                }
                const fadedWord = sentenceElement.querySelector('.faded-word')
                if (fadedWord) {
                    setTimeout(()=>{
                        sentenceElement.querySelector('.faded-word').classList.add('faded-activated');
                    }, SENTENCE_DELAY)
                }
            }
        }

        if (template === 'glossary') {
            return (
                <div
                    className={"whatever-class humanPost-Page page exit-Down " + (this.state.fromRandomTeaser ? 'enter-Right' : 'enter-Up')}>
                    {/*<HomeLink history={this.props.history} clickHomeLink={() => this.clickHomeLink()}/>*/}

                    <HelmetHeader title={seoTitle} seoSlug={seoSlug} description={seoDescription} imageUrl={seoImageUrl}></HelmetHeader>

                    <div className="headerDiv headerDiv-artist">
                        <div className="main-border">
                            <div className="glossary-template-wrapper">
                                <p className="glossary-template__title">{this.props.contributionData.templateGlossaryTitle}</p>
                                <p className="glossary-template__text">{this.props.contributionData.templateGlossaryDefinitionOne}</p>
                                <p className="glossary-template__quote">{this.props.contributionData.templateGlossaryExampleOne}</p>
                                {this.props.contributionData.templateGlossaryDefinitionTwo &&
                                <p className="glossary-template__text">{this.props.contributionData.templateGlossaryDefinitionTwo}</p>
                                }
                                {this.props.contributionData.templateGlossaryExampleTwo &&
                                <p className="glossary-template__quote">{this.props.contributionData.templateGlossaryExampleTwo}</p>
                                }
                            </div>
                        </div>
                    </div>

                    <PanelFooter
                        contributionId={this.props?.contributionData.id}
                        history={this.props.history}
                        urlPath={this.props.contributionData.title}
                        isHumanPostPage={true}
                        isContactOpen={this.state.isContactOpen}
                        handler={this.openContactForm}
                        onClickLogo={(e) => this.handleRedirectHome(e)}
                        activeFormStep={this.state.activeStep}
                        onClikHeaderCategory={() => this.handleRedirectHuman()}>
                    </PanelFooter>
                </div>
            )
        }

        if (template === 'fullscreenVideo') {
            const tabs = ['Media', 'Info'];
            const bodyEl = document.querySelector('body');
            let timer;
            bodyEl.addEventListener('mousemove', () => {
                clearTimeout(timer);
                let pausebutton = document.querySelector('.pause-btn-js');
                if (pausebutton && this.state.isPlaying) {
                    pausebutton.style.display = "block";
                }
                if (pausebutton) {
                    timer = setTimeout(() => {
                        pausebutton.style.display = "none"
                    }, 1000);
                }
            });

            return (
                <div
                    className={"whatever-class humanPost-Page page exit-Down " + (this.state.fromRandomTeaser ? 'enter-Right' : 'enter-Up')}
                >
                    <HelmetHeader title={seoTitle} seoSlug={seoSlug} description={seoDescription} imageUrl={seoImageUrl}></HelmetHeader>

                    {/*<HomeLink history={this.props.history} clickHomeLink={() => this.clickHomeLink()}/>*/}
                    <div className="headerDiv headerDiv-artist fullscreen-video__header">
                        <div className="main-border">
                            <div
                                className={this.state.isEnlarged ? 'gallery-zoom__wrapper gallery-zoom__wrapper--active' : 'gallery-zoom__wrapper'}>
                                <div className={this.state.activeTab === 0 ? 'gallery-zoom--active' : 'gallery-zoom'}>
                                    <div className="fullscreen-video-wrapper">
                                        <div className="fullscreen-video-player__wrapper">

                                            <div className="fullscreen-video-player__loadingInfo">
                                                <div className="fullscreen-video-player__loadingInfoIcon"></div>
                                                <div>LOADING FOR OPTIMIZED EXPERIENCE...</div>
                                            </div>

                                            <ReactPlayer
                                                ref={this.fullscreenVideo}
                                                className='fullscreen-video-player'
                                                url={this.props.contributionData.templateFullscreenVideoVideoUrl}
                                                playing={this.state.isPlaying}
                                                volume="1"
                                                width="100%"
                                                height="100%"
                                                muted={!this.state.muted}
                                                progressInterval={500}
                                                onProgress={this.updateProgressBar}
                                                onReady={this.hideLoadingInfo}
                                                onDuration={this.updateTotalTime}
                                            />


                                            <div className="fullscreen-video-btn play-btn-js" onClick={() => this.playVideo()}>
                                                <img src={require("../../assets/video-player/Play_Button_hover.svg")} alt="play"/>
                                            </div>

                                            <div className="fullscreen-video-btn pause-btn-js" onClick={() => this.pauseVideo()}>
                                                <img src={require("../../assets/video-player/Pause_Hover.svg")} alt="pause"/>
                                            </div>

                                            <div className="fullscreen-enlarge__btn" onClick={this.onClickFullscreen}>
                                                <img src={require("../../assets/enlarge.svg")} alt="enlarge"/>
                                            </div>

                                            {/*Progress bar*/}
                                            <div className="fullscreen-video__playInfo">
                                                <div className="fullscreen-video__progressDuration">
                                                    <div className="fullscreen-video__progress"></div>
                                                </div>
                                                <div className="fullscreen-video__timeInfo">
                                                    <span className="fullscreen-video__timeInfoCurrent">0:00</span>
                                                    <span className="fullscreen-video__timeInfoSeparator"> / </span>
                                                    <span className="fullscreen-video__timeInfoTotal">0:00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.muted ?
                                        <img src={require("../../assets/UNMUTED.svg")} alt="" className="fullscreen-video__mute" onClick={() => this.onToggleMute()} />
                                        :
                                        <img src={require("../../assets/MUTED.svg")} alt="" className="fullscreen-video__mute" onClick={() => this.onToggleMute()} />
                                    }
                                    <img src={require("../../assets/setting-btn.svg")} alt="" onClick={() => {this.openGalleryDrawer();}} className="gallery-zoom__btn" />
                                </div>
                                <div
                                    className={this.state.isEnlarged && this.state.width > 800 || this.state.activeTab === 1 ? 'gallery-zoom__drawer gallery-zoom__drawer--active' : 'gallery-zoom__drawer'}>
                                    { column3 }
                                </div>
                            </div>
                            <div className="tabs justify-content-around">
                                {tabs.map((data, i) => {
                                        return (
                                            <span
                                                key={i}
                                                className={i === this.state.activeTab ? 'tab tab--active' : 'tab'}
                                                onClick={(e) => this.handleTabChange(e, i)}>
                                                {data}
                                            </span>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>

                    <PanelFooter
                        contributionId={this.props?.contributionData.id}
                        history={this.props.history}
                        urlPath={this.props.contributionData.title}
                        isHumanPostPage={true}
                        isContactOpen={this.state.isContactOpen}
                        handler={this.openContactForm}
                        onClickLogo={(e) => this.handleRedirectHome(e)}
                        activeFormStep={this.state.activeStep}
                        onClikHeaderCategory={() => this.handleRedirectHuman()}>
                    </PanelFooter>
                </div>
            )
        }

        if (template === 'image1') {
            const tabs = ['Media', 'Info'];

            return (
                <div
                    className={"whatever-class humanPost-Page page exit-Down " + (this.state.fromRandomTeaser ? 'enter-Right' : 'enter-Up')}>

                    <HelmetHeader title={seoTitle} seoSlug={seoSlug} description={seoDescription} imageUrl={seoImageUrl}></HelmetHeader>

                    <div className="headerDiv headerDiv-artist image-one__header">
                        <div className="main-border">
                            <div
                                className={this.state.isEnlarged ? 'gallery-zoom__wrapper gallery-zoom__wrapper--active' : 'gallery-zoom__wrapper'}>
                                <div className={this.state.activeTab === 0 ? 'gallery-zoom--active' : 'gallery-zoom'}>                                    
                                    <div className="fullscreen-video-player__wrapper image-one-wrapper">
                                        <img src={this.props.contributionData.templateImageImage[0].url} alt=""></img>
                                    </div>
                                </div>
                                <div
                                    className={'gallery-zoom__drawer gallery-zoom__drawer--active'}>
                                    { column3 }
                                </div>
                            </div>
                            <div className="tabs justify-content-around">
                                {tabs.map((data, i) => {
                                        return (
                                            <span
                                                key={i}
                                                className={i === this.state.activeTab ? 'tab tab--active' : 'tab'}
                                                onClick={(e) => this.handleTabChange(e, i)}>
                                                {data}
                                            </span>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>

                    <PanelFooter
                        contributionId={this.props?.contributionData.id}
                        history={this.props.history}
                        urlPath={this.props.contributionData.title}
                        isHumanPostPage={true}
                        isContactOpen={this.state.isContactOpen}
                        handler={this.openContactForm}
                        onClickLogo={(e) => this.handleRedirectHome(e)}
                        activeFormStep={this.state.activeStep}
                        onClikHeaderCategory={() => this.handleRedirectHuman()}>
                    </PanelFooter>
                </div>
            )
        }

        if (template === 'image2') {
            const tabs = ['Media', 'Info'];

            return (
                <div
                    className={"whatever-class humanPost-Page page exit-Down " + (this.state.fromRandomTeaser ? 'enter-Right' : 'enter-Up')}>

                    <HelmetHeader title={seoTitle} seoSlug={seoSlug} description={seoDescription} imageUrl={seoImageUrl}></HelmetHeader>

                    <div className="headerDiv headerDiv-artist fullscreen-video__header">
                        <div className="main-border">
                            <div
                                className={this.state.isEnlarged ? 'gallery-zoom__wrapper gallery-zoom__wrapper--active' : 'gallery-zoom__wrapper'}>
                                <div className={this.state.activeTab === 0 ? 'gallery-zoom--active' : 'gallery-zoom'}>
                                    <div className="fullscreen-video-wrapper">
                                        <img src={this.props.contributionData.templateImageImage[0].url} 
                                            onClick={this.state.isEnlarged ? this.closeGalleryDrawer : null} alt="" />
                                    </div>
                                    <img src={require("../../assets/setting-btn.svg")} alt="" onClick={() => {this.openGalleryDrawer();}} className="gallery-zoom__btn" />
                                </div>
                                <div
                                    className={this.state.isEnlarged && this.state.width > 800 || this.state.activeTab === 1 ? 'gallery-zoom__drawer gallery-zoom__drawer--active' : 'gallery-zoom__drawer'}>
                                    { column3 }
                                </div>
                            </div>
                            <div className="tabs justify-content-around">
                                {tabs.map((data, i) => {
                                        return (
                                            <span
                                                key={i}
                                                className={i === this.state.activeTab ? 'tab tab--active' : 'tab'}
                                                onClick={(e) => this.handleTabChange(e, i)}>
                                                {data}
                                            </span>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>

                    <PanelFooter
                        contributionId={this.props?.contributionData.id}
                        history={this.props.history}
                        urlPath={this.props.contributionData.title}
                        isHumanPostPage={true}
                        isContactOpen={this.state.isContactOpen}
                        handler={this.openContactForm}
                        onClickLogo={(e) => this.handleRedirectHome(e)}
                        activeFormStep={this.state.activeStep}
                        onClikHeaderCategory={() => this.handleRedirectHuman()}>
                    </PanelFooter>
                </div>
            )
        }

        if (template === 'image3') {
            return (
                <div className={"whatever-class humanPost-Page page exit-Down " + (this.state.fromRandomTeaser ? 'enter-Right' : 'enter-Up')}>
                    {/*<HomeLink history={this.props.history} clickHomeLink={() => this.clickHomeLink()}/>*/}

                    <HelmetHeader title={seoTitle} seoSlug={seoSlug} description={seoDescription} imageUrl={seoImageUrl}></HelmetHeader>

                    <div className="headerDiv headerDiv-artist">
                        <Row className={this.state.isContactOpen ? "main-border py-0" : "main-border"}>
                            <Col
                                onMouseOver={(e) => this.changeTitle(e)}
                                id="first-column"
                                md={6} sm={12} xs={12}
                                className={this.state.activeTab === 0 ? 'panels-item--active' : 'first-column'}
                                style={{position: 'relative'}}>
                                {/* {this.images &&
                                        <>
                                        <div className="image-gallery-container">
                                            <div className="image-gallery-wrapper">
                                                <ImageGallery
                                                    items={this.images}
                                                    showThumbnails={false}
                                                    showPlayButton={false}
                                                    onSlide={(index) => this.changeSlider(index)}
                                                />
                                            </div>
                                        </div>
                                        {this.images[this.state.activeIndex].description &&
                                        <p className="gallery-description">
                                            {window.screen.width <= 375
                                                ?
                                                this.images[this.state.activeIndex].description.split(',').join("\n")
                                                :
                                                this.images[this.state.activeIndex].description
                                            }
                                        </p>}
                                </>} */}
                                <div className="fullscreen-video-player__wrapper image-one-wrapper">
                                    <img src={this.props.contributionData.templateImageImage[0].url} alt="" width="100%"></img>
                                </div>
                            </Col>
                            <Col
                                md={6} sm={12} xs={12}
                                className="first-second-column"
                                style={{ }}>
    
                                <Row>
                                    <Col
                                        onMouseOver={(e) => this.changeTitle(e)}
                                        id="panels-item--two"
                                        className={'panels-item panels-item--two panels-item--oneTwo'}
                                        lg={4} md={6} sm={6} xs={12}
                                        onClick={this.showColumn}>
                                            <h2 className="h2HeadingStyle">
                                                {
                                                    typeof(this.props.contributionData.columnTwoText) !== 'undefined' ?
                                                        <div>{ ReactHtmlParser(this.props.contributionData.columnTwoText) }</div>
                                                        :
                                                        ''
                                                }
                                            </h2>
                                    </Col>
                                    <Col id="panels-item--three"
                                         onMouseOver={(e) => this.changeTitle(e)}
                                         className={'panels-item panels-item--three'}
                                         lg={5} md={5} sm={6} xs={12}
                                         onClick={this.showColumn}>
                                            <>
                                                <HumanPostForm
                                                    isContactOpen={this.state.isContactOpen}
                                                    openContactForm={this.openContactForm}
                                                    humanPostData={this.props.humanPostData}
                                                    handlePostSubmit={this.onFormSubmit}
                                                    activeStep={this.state.activeStep}/>
                                                    <div className="column3-header">
                                                        {this.props.contributionData.title} <br/>
                                                        {this.props.contributionData.artistName && this.props.contributionData.artistName}
                                                    </div>
                                                    {this.props.contributionData.price && this.props.contributionData.readingText ?
                                                        <span>—</span>
                                                        :
                                                        null
                                                    }
                                                    <div className="column3-description">
                                                        {this.props.contributionData.buy_column_description &&
                                                        <p className="column3-description__item">
                                                            {this.props.contributionData.buy_column_description}
                                                        </p>}
                                                        {this.props.contributionData.technique &&
                                                        <p className="column3-description__item">
                                                            Technique: {this.props.contributionData.technique}
                                                        </p>
                                                        }
                                                        {this.props.contributionData.fileType &&
                                                        <p className="column3-description__item">
                                                            File Type: {this.props.contributionData.fileType}
                                                        </p>
                                                        }
                                                        {this.props.contributionData.editionNumber &&
                                                        <p className="column3-description__item">
                                                            EDITION NUMBER: {this.props.contributionData.editionNumber}
                                                        </p>
                                                        }
                                                        {this.props.contributionData.collectorReceives &&
                                                        <p className="column3-description__item">
                                                            Collector
                                                            ReceiveS: {this.props.contributionData.collectorReceives}
                                                        </p>
                                                        }
                                                        {this.props.contributionData.readingText ?
                                                            <span>—</span>
                                                            :
                                                            null
                                                        }
                                                        {this.props.contributionData.readingText &&
                                                            <>
                                                                <p className="column3-description__item column3-description__item--lowercase">
                                                                   { ReactHtmlParser(this.props.contributionData.readingText) }
                                                                </p>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="mb20">
                                                        {
                                                            this.props.contributionData.participating_artists &&
                                                            <h2>Participating artists</h2>
                                                        }
    
                                                        <div className="artistsList">
                                                            {
                                                                this.props.contributionData.participating_artists &&
                                                                this.props.contributionData.participating_artists.map((data, i) => {
                                                                    return (
                                                                        data.participating_artist !== null ?
                                                                            <p key={"artist" + i} className="aTagStyle"
                                                                               onClick={(item) => this.handleRedirectArtist(data.participating_artist.post_name)}>
                                                                                <a href={data.participating_artist.postname}><span>{data.participating_artist.post_title}</span></a>
                                                                            </p>
                                                                            :
                                                                            ''
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        this.props.contributionData.link_for_website !== "" &&
                                                        <a className="aTagStyle"
                                                           href={this.props.contributionData.link_for_website}>[link for
                                                            website]</a>
                                                    }
    
                                                    <>
                                                        {this.props.contributionData.price &&
                                                            <>
                                                                <span>—</span>
                                                                <p className="panels-item__nft-price">
                                                                    Price: <span
                                                                    className="text-lowercase">{this.props.contributionData.price}</span>
                                                                </p>
                                                            </>
                                                        }
    
                                                        <div className="column-3__footer">
                                                            {this.props.contributionData.price &&
                                                                <a onClick={this.openContactForm} className="btn--link">
                                                                    Purchase ≥
                                                                </a>
                                                            }
    
                                                            <a onClick={this.copyUrlToClipboard} className="share__btn btn--link">
                                                                Share
                                                            </a>
                                                        </div>
                                                    </>
                                            </>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
    
                    <div className="share-message__wrapper">
                        <span>Exhibition URL copied to clipboard - ready to share!</span>
                    </div>
    
                    {!this.state.isEnlarged && !this.state.isContactOpen &&
                        <img className="humanPost-Page__logo" src={logo} alt=""/>
                    }
    
                    <PanelFooter
                        contributionId={this.props?.contributionData.id}
                        history={this.props.history}
                        urlPath={this.props.contributionData.title}
                        isHumanPostPage={true}
                        isContactOpen={this.state.isContactOpen}
                        handler={this.openContactForm}
                        onClickLogo={(e) => this.handleRedirectHome(e)}
                        activeFormStep={this.state.activeStep}
                        onClikHeaderCategory={() => this.handleRedirectHuman()}>
                    </PanelFooter>
                </div>
            );
        }

        return (
            <div className={"whatever-class humanPost-Page page exit-Down " + (this.state.fromRandomTeaser ? 'enter-Right' : 'enter-Up')}>
                {/*<HomeLink history={this.props.history} clickHomeLink={() => this.clickHomeLink()}/>*/}

                <HelmetHeader title={seoTitle} seoSlug={seoSlug} description={seoDescription} imageUrl={seoImageUrl}></HelmetHeader>
                
                <div className="headerDiv headerDiv-artist">
                    {template === 'text' || template === 'animatedQuote'
                    ?
                    <Row className="main-border">
                        <div className={template === 'animatedQuote' ? "text-template-wrapper animated-quote-wrapper" : "text-template-wrapper"}>
                            <p className="text-template__text">
                                { ReactHtmlParser(this.props.contributionData.templateAnimatedQuoteMainText) }
                            </p>
                            <p className="text-template__description">
                                { ReactHtmlParser(this.props.contributionData.templateAnimatedQuoteSource) }
                            </p>
                        </div>
                    </Row>
                    :
                    <Row className={this.state.isContactOpen ? "main-border py-0" : "main-border"}>
                        {template === 'video1' || template === 'video2Bigfont' ?
                        <Col
                            onMouseOver={(e) => this.changeTitle(e)}
                            id="first-column"
                            md={template === 'video1' ? 8 : template === 'video2Bigfont' ? 6 : 4} sm={12} xs={12}
                            className={this.state.activeTab === 0 ? 'panels-item--active' : 'first-column'}
                            style={{position: 'relative'}}>
                            <div className="video-container">
                                <div className={template === 'video2Bigfont' ? 'video-iframe' : 'video-iframe video2-iframe'}>
                                    <iframe
                                        src={this.props.contributionData.templateVideo123VideoUrl.url}
                                        frameBorder="0" allowFullScreen="allowfullscreen"
                                        title="Video"
                                    />
                                </div>
                                {/*{ ReactHtmlParser(this.props.humanPostData.acf.column_1) }*/}
                            </div>
                        </Col>
                        :
                        <Col
                            onMouseOver={(e) => this.changeTitle(e)}
                            id="first-column"
                            md={template === 'video1' ? 8 : template === 'video2Bigfont' || template === 'gallery-big' ? 6 : 4} sm={12} xs={12}
                            className={this.state.activeTab === 0 ? 'panels-item--active' : 'first-column'}
                            style={{position: 'relative'}}>
                            {template !== 'gallery-big' ?
                                <>
                                    {this.images &&
                                    <>
                                    <div className="image-gallery-container">
                                        <div className="image-gallery-wrapper">
                                            <ImageGallery
                                                items={this.images}
                                                showThumbnails={false}
                                                showPlayButton={false}
                                                onSlide={(index) => this.changeSlider(index)}
                                            />
                                        </div>
                                    </div>
                                    {this.images[this.state.activeIndex].description &&
                                    <p className="gallery-description">
                                        {window.screen.width <= 375
                                            ?
                                            this.images[this.state.activeIndex].description.split(',').join("\n")
                                            :
                                            this.images[this.state.activeIndex].description
                                        }
                                    </p>}
                                    </>}
                                </>
                                :
                                <div className={this.state.isEnlarged ? 'gallery-big gallery-big--enlarged' : 'gallery-big'}>
                                    <ScrollContainer className="scroll-container">
                                    {this.props.humanPostData.acf.panorama_image &&
                                        <img className="gallery-big__img" src={this.props.humanPostData.acf.panorama_image}
                                             alt=""/>
                                    }
                                    <div className={this.state.isEnlarged ? 'gallery-big__btn gallery-big__btn--enlarged' : 'gallery-big__btn'} onClick={this.enlargePhoto}/>
                                    </ScrollContainer>
                                    {this.props.humanPostData.acf.column_1 &&
                                    <p className="gallery-description">
                                        {window.screen.width <= 375 &&
                                        <>
                                            { ReactHtmlParser(this.props.humanPostData.acf.column_1.split(',').join("\n")) }
                                        </>
                                        }
                                    </p>}
                                </div>
                            }
                        </Col>}
                        <Col id={this.state.activeTab === 0 ? '' : !this.state.isContactOpen ? 'third-fourth-column' : 'contact-column'}
                             md={template === 'video1' ? 4 : template === 'video2Bigfont' || template === 'gallery-big' ? 6 : 8}
                             sm={12} xs={12}
                             className="first-second-column"
                             style={{ }}>

                            <Row>
                                {template !== 'video1' &&
                                <>
                                {template !== 'video2Bigfont' && template !== 'gallery-big' ?
                                <Col
                                    onMouseOver={(e) => this.changeTitle(e)}
                                    id="panels-item--one"
                                    className={this.state.activeTab === 1 ? 'panels-item panels-item--one panels-item--active' : 'panels-item panels-item--one'}
                                    lg={4} md={4} sm={6} xs={12}
                                    onClick={this.showColumn}>
                                    { ReactHtmlParser(this.props.contributionData?.columnOneText) }
                                </Col> : ''}
                                <Col
                                    onMouseOver={(e) => this.changeTitle(e)}
                                    id="panels-item--two"
                                    className={this.state.activeTab === 1 ? 'panels-item panels-item--two panels-item--active' : 'panels-item panels-item--two'}
                                    lg={4} md={6} sm={6} xs={12}
                                    onClick={this.showColumn}>

                                    {this.props.contributionData?.template === 'video3Smallfont'
                                        ?
                                        <p className="video-3__text">
                                            {
                                                typeof(this.props.contributionData?.columnTwoText) !== 'undefined' ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.props.contributionData?.columnTwoText.replace(/<\/?[^>]+(>|$)/g, "")}}></div>
                                                    :
                                                    ''
                                            }
                                        </p>
                                        :
                                        <h2 className="h2HeadingStyle">
                                            {
                                                this.props?.contributionData?.columnTwoText && typeof(this.props?.contributionData?.columnTwoText) !== 'undefined' ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.props?.contributionData?.columnTwoText.replace(/<\/?[^>]+(>|$)/g, "")}}></div>
                                                    :
                                                    ''
                                            }
                                        </h2>
                                    }
                                </Col>
                                </>}

                                <Col id="panels-item--three"
                                     onMouseOver={(e) => this.changeTitle(e)}
                                     className={this.state.activeTab === tabs.length - 1 ? 'panels-item panels-item--three panels-item--active' : 'panels-item panels-item--three'}
                                     lg={5} md={5} sm={6} xs={12}
                                     onClick={template !== 'video1' ? this.showColumn : undefined}>

                                    {template !== 'default'
                                        ?
                                        <>
                                            <HumanPostForm
                                                isContactOpen={this.state.isContactOpen}
                                                openContactForm={this.openContactForm}
                                                humanPostData={this.props.humanPostData}
                                                handlePostSubmit={this.onFormSubmit}
                                                activeStep={this.state.activeStep}/>

                                            {!this.state.isContactOpen &&
                                            <>
                                                <div className="column3-header">
                                                    {this.props.contributionData.title} <br/>
                                                    {this.props.contributionData.artistName && this.props.contributionData.artistName}
                                                </div>
                                                {this.props.contributionData.price && this.props.contributionData.readingText ?
                                                    <span>—</span>
                                                    :
                                                    null
                                                }
                                                <div className="column3-description">
                                                    {this.props.contributionData.buy_column_description &&
                                                    <p className="column3-description__item">
                                                        {this.props.contributionData.buy_column_description}
                                                    </p>}
                                                    {this.props.contributionData.technique &&
                                                    <p className="column3-description__item">
                                                        Technique: {this.props.contributionData.technique}
                                                    </p>
                                                    }
                                                    {this.props.contributionData.fileType &&
                                                    <p className="column3-description__item">
                                                        File Type: {this.props.contributionData.fileType}
                                                    </p>
                                                    }
                                                    {this.props.contributionData.editionNumber &&
                                                    <p className="column3-description__item">
                                                        EDITION NUMBER: {this.props.contributionData.editionNumber}
                                                    </p>
                                                    }
                                                    {this.props.contributionData.collectorReceives &&
                                                    <p className="column3-description__item">
                                                        Collector
                                                        ReceiveS: {this.props.contributionData.collectorReceives}
                                                    </p>
                                                    }
                                                    {this.props.contributionData.readingText ?
                                                        <span>—</span>
                                                        :
                                                        null
                                                    }
                                                    {this.props.contributionData.readingText &&
                                                        <>
                                                            <p className="column3-description__item column3-description__item--lowercase">
                                                               {ReactHtmlParser(this.props.contributionData.readingText)}
                                                            </p>
                                                        </>
                                                    }
                                                </div>
                                                <div className="mb20">
                                                    {
                                                        this.props.contributionData.participatingArtists &&
                                                        <h2>Participating artists</h2>
                                                    }

                                                    <div className="artistsList">
                                                        {
                                                            this.props.contributionData.participatingArtists &&
                                                            this.props.contributionData.participatingArtists.map((data, i) => {
                                                                return (
                                                                    data.participating_artist !== null ?
                                                                        <p key={"artist" + i} className="aTagStyle"
                                                                           onClick={(item) => this.handleRedirectArtist(data.participating_artist.post_name)}>
                                                                            <a href={data.participating_artist.postname}><span>{data.participating_artist.post_title}</span></a>
                                                                        </p>
                                                                        :
                                                                        ''
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    false && this.props.contributionData?.link_for_website !== "" &&
                                                    <a className="aTagStyle"
                                                       href={this.props.humanPostData.acf.link_for_website}>[link for
                                                        website]</a>
                                                }

                                                <>
                                                    {this.props.contributionData.price &&
                                                        <>
                                                            <span>—</span>
                                                            <p className="panels-item__nft-price">
                                                                Price: <span
                                                                className="text-lowercase">{this.props.contributionData.price}</span>
                                                            </p>
                                                        </>
                                                    }

                                                    <div className="column-3__footer">
                                                        {this.props.contributionData.price &&
                                                            <a onClick={this.openContactForm} className="btn--link">
                                                                Purchase ≥
                                                            </a>
                                                        }

                                                        <a onClick={this.copyUrlToClipboard} className="share__btn btn--link">
                                                            Share
                                                        </a>
                                                    </div>
                                                </>
                                            </>}
                                        </>
                                        :
                                        <>
                                            <div className="column3-header">
                                                {this.props.contributionData.title} <br/>
                                                {this.props.contributionData.artistName && this.props.contributionData.artistName}
                                            </div>
                                            <span>—</span>
                                            <div className="column3-description">
                                                {this.props.contributionData.columnThreeText &&
                                                    <p className="column3-description__item">
                                                        { ReactHtmlParser(this.props.contributionData.columnThreeText) }
                                                    </p>
                                                }
                                            </div>
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Col>

                        {!this.state.isContactOpen &&
                        <div className={template === 'video1' ? "tabs justify-content-around" : "tabs"}>
                            {tabs.map((data, i) => {
                                    return (
                                        <span
                                            key={i}
                                            className={i === this.state.activeTab ? 'tab tab--active' : 'tab'}
                                            onClick={(e) => this.handleTabChange(e, i)}>
                                                { data }
                                            </span>
                                    )
                                }
                            )}
                        </div>
                        }
                    </Row>}
                </div>

                <div className="share-message__wrapper">
                    <span>Exhibition URL copied to clipboard - ready to share!</span>
                </div>

                {!this.state.isEnlarged && !this.state.isContactOpen &&
                    <img className="humanPost-Page__logo" src={logo} alt=""/>
                }

                <PanelFooter
                    contributionId={this.props?.contributionData.id}
                    history={this.props.history}
                    urlPath={this.props.contributionData.title}
                    isHumanPostPage={true}
                    isContactOpen={this.state.isContactOpen}
                    handler={this.openContactForm}
                    onClickLogo={(e) => this.handleRedirectHome(e)}
                    activeFormStep={this.state.activeStep}
                    onClikHeaderCategory={() => this.handleRedirectHuman()}>
                </PanelFooter>
            </div>
        );
    }

    updateTotalTime(totalDurationSeconds){

        let currentTimeSeconds = document.querySelector('.fullscreen-video__timeInfoTotal');
        //let totalTimeSeconds = document.querySelector('.fullscreen-video__timeInfoTotal');

        let playedMinutes = Math.floor(totalDurationSeconds/60);
        let playedMinutesText = '';

        if(playedMinutes<10){
            playedMinutesText = "0"+playedMinutes;
        } else {
            playedMinutesText = playedMinutes;
        }

        let playedSeconds = Math.floor(totalDurationSeconds) - playedMinutes*60;
        let playedSecondsText = '';
        if(playedSeconds<10){
            playedSecondsText = "0"+playedSeconds;
        } else {
            playedSecondsText = playedSeconds;
        }
        currentTimeSeconds.innerHTML = playedMinutesText+":"+playedSecondsText;
    }

    hideLoadingInfo() {
        let progressIndicator = document.querySelector('.fullscreen-video-player__loadingInfo');
        progressIndicator.style.display = "none";

        let playbutton = document.querySelector('.play-btn-js');
        playbutton.style.display = "block";

        let playInfo = document.querySelector('.fullscreen-video__playInfo');
        playInfo.style.display = "block";
    }
}

function mapStateToProps(state) {
    return {
        humanPostData: state.humanPostData,
        contributionData: state.contributionData,
        mainData: state.mainData,
        //guideStatus: state.guideStatus,
        guideRoom: state.guideStatus.guideRoom,
        userStatus: state.userStatus,
    };
}

const mapDispatchToProps = {
    getPostDataBySlug: baadActions.getPostDataBySlug,
    getHumanPostDataBySlug: baadActions.getHumanPostDataBySlug,
    getPageDataBySlug: baadActions.getPageDataBySlug,
    mouseIn: baadActions.mousePosIn,
    mouseOut: baadActions.mousePosOut,
    getContributionData: baadActions.getContributionData,
    getMainData: baadActions.getMainData,
    setCursorActive: baadActions.setCursorActive,
}

export default connect(mapStateToProps, mapDispatchToProps)(humanPost);
