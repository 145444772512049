import React from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import routes from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserHistory } from 'history';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {Redirect, withRouter} from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CursorChat from './components/CursorChat';


require('dotenv').config()

const history = createBrowserHistory();

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

var is_guide = false;
var guide_url = '';

if (process.env.NODE_ENV === `development`) {
  //show_chat = true;
}

function App(props) {

  const location = useLocation();

  let query = useQuery();
  if(!is_guide){
    is_guide = parseInt(query.get("guide")) === 1 ? true : false;
    guide_url = query.get("guide_url");
    //guide_url = 'https://www.youtube.com/embed/Q9gis7-Jads?autoplay=1';
  }

  return (
    <div>
      {/*<Router>*/}
        <TransitionGroup>
          <CSSTransition
            timeout={{enter: 700, exit: 700}}
            key={location.key}
            classNames="page">
              <>
                <Switch location={location}>
                  {routes.map((route, index) => {
                    return (<Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                      history={history}
                    // state={route.state}
                    />)
                  })}
                  <Redirect to="/" />
                </Switch>
              </>
          </CSSTransition>
        </TransitionGroup>
        {/*</Router>*/}
        {location?.pathname !== '/' &&
          <CursorChat isGuide={is_guide} guideUrl={guide_url} ></CursorChat>
        }
    </div>
  );
}

const mapStateToProps = state => {
  /*const frontUrl = state.frontUrl;
  if(frontUrl ===''){
    state.lineWidth = "1px";
  }*/
  return {
    lineWidth: state.lineWidth,
    mousePos: state.mousePos,
    frontUrl: state.frontUrl
  }
}
export default compose(
  withRouter,
  connect(mapStateToProps)
)(App);
