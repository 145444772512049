import React,{ useCallback, useEffect, useMemo, useState, useRef } from 'react';
import {useSelector } from "react-redux";

export const CursorUserString = ({
    room
    }) => {

    const userList = useSelector((state) => state.presenceData);
    const userListRoom = userList.filter((user) => user.room === '/h/' + room);
    
    if(userListRoom.length < 1) {
        return null;
    }
    return (
        <>
            <div className='user-list-string'>
                {userListRoom.map((user, index) =>
                    <span className="user-icon" key={index} style={{ backgroundColor: user?.color?.inactive }}></span>
                ) 
                }
            </div>
        </>
    );
};