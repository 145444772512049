import React, { Component } from "react";
import './Button.scss';
import { Link } from "react-router-dom";

export default class Button extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { btnText, dark = false, to, type } = this.props;
        return (
            <>
                {to
                    ?
                    <Link to={to} className={dark ? 'btn-outlined btn-outlined--dark' : 'btn-outlined'}>{ btnText }</Link>
                    :
                    <button type={type} className={dark ? 'btn-outlined btn-outlined--dark' : 'btn-outlined'}>{ btnText }</button>
                }
            </>
        )
    }
}
