import createSagaMiddleware from 'redux-saga';
import { configureStore, createSlice, getDefaultMiddleware } from "@reduxjs/toolkit";
import reducer from './reducers';
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [];
if (process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`);
    //middlewares.push(logger);
}

function makeStore() {
    return configureStore({
        reducer: reducer,
        middleware: [...getDefaultMiddleware(), sagaMiddleware, ...middlewares],
        /*enhancers: [
            enhancer({
                client,
                presenceMapping: {
                    cursor: true,
                    room: true,
                },
            }),
        ],*/
    });
}
const store = makeStore();

sagaMiddleware.run(rootSaga);

export default store;