import * as React from "react"
import styled from "styled-components";


const hexToRgb = (hexInput) => {
    let hex = hexInput ?? false;
    // http://stackoverflow.com/a/5624139
    if(hex){
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
    } else {
        return null;
    }
  };
  
const rgba = (hex, alpha) => {
    const color = hexToRgb(hex);
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};

const CursorActive =  styled.div`
	background-color:  ${props => props.mainColor};
	border-radius: 50%;	
	height: 18px;
	width: 18px;

    &.active {
        //animation: pulse-${props => props.mainColor.replace('#', '')} 1.1s infinite;
        border: 2px solid #0038FF;
    }

    @keyframes pulse-${props => props.mainColor.replace('#', '')} {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 ${props => rgba(props.mainColor, 0.9)};
	}

	70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 18px ${props => rgba(props.mainColor, 0)};
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 ${props => rgba(props.mainColor, 0)};
	}
}

`;

export const Cursor = ({color, active}) => {
    let colorLocal = '#2E75E4';
    if(color?.primary){
        colorLocal = color?.primary;
    }
    return(
        <>
            <div className="cursor-local__wrapper">
                <CursorActive className={(active === true ? 'active' : '')} mainColor={colorLocal} />
            </div>
        </>
    )
}