import React from 'react';
import { CursorPerfect } from './CursorPerfect';
import { getMousePosition } from './helper';

export const OtherCursor = ({
    position,
    room,
    message,
    color,
    isTyping,
    isGuide,
    numberOfGuideUsers
    }) => {

        const otherCursorPos = getMousePosition(position?.scaleX, position?.scaleY );

        return (
            <CursorPerfect point={[otherCursorPos?.mouseX, otherCursorPos?.mouseY]} message={message} color={color} isTyping={isTyping} isGuide={isGuide} numberOfGuideUsers={numberOfGuideUsers}></CursorPerfect>
        );
};