import React from "react";

export default function Input({
    required = false,
    register,
    name,
    type,
    placeholder,
    errors
    }) {
    return (
        <>
            <input
                {...register(name, { required })}
                id={name}
                type={type}
                placeholder={placeholder}
                className="input input--outlined"/>
            {errors && (
                <span role="alert" className="form-alert">
                 This field is required
                </span>
            )}
        </>
    )
}
